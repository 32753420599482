import { useEffect } from "react";

export const useClickOutSide = ( ref, callback ) => {
  useEffect( () => {
    const handleOutSide = ( event ) => {
      if ( ref.current && !ref.current.contains( event.target ) ) {
        callback();
      }
    };

    document.addEventListener( "mouseup", handleOutSide );
    return () => {
      document.addEventListener( "mouseup", handleOutSide );
    };
  }, [ callback, ref ] );
};
