import styles from "./Methodology.module.scss";
import Button from "@Ui/Buttons/Button";
import { Typography } from "@Typography";
import { Link } from "react-router-dom";
import { paths } from "../../../../Utils/Constans/constants";
import { methodologyConstants } from "./constants/constants";
import useMediaQuery from "@Utils/hooks/useMediaQuery";
import { useTranslation } from "react-i18next";
import alternatePhoto from "@Assets/Images/alternativePhoto.jpg";



export const Methodology = () => {
  const isMobile = useMediaQuery( "(max-width: 768px)" ); 
  const { t } = useTranslation( "homepage" );

  const handleImageError = ( event ) => {
    event.target.src = alternatePhoto;
  };

  return (
    <div className="Container">
      <div className={styles.methodologyContainer}>
        <Typography
          variant="h1"
          weight="medium"
          color="DarkBlue"
        >
          {t( "methods.title" )}
        </Typography>
        <Typography
          variant="bodyExtraSmall"
          weight="medium"
          className={styles.methodologyTitle}
        >
          {t( "methods.description" )}
        </Typography>
        <div className={styles.methodology}>
          {methodologyConstants.map( ( item, index ) => (
            <div
              className={styles.methodologyBlock}
              key={index}
              style={{ background: item.background }}
            >
              <div className={styles.MethodologyFoto}> <img
                src={item.img || alternatePhoto} 
                alt="Методика опосредованного обучения" 
                onError={handleImageError}
              /></div>
             
              <div className={styles.methodologyInfo}>
                <Typography
                  variant="h3"
                  weight="medium"
                  color={isMobile ? item.color : "black"}
                >
                  {t( item.title )}
                </Typography>
                <Typography
                  variant="bodyExtraSmall"
                  weight="regular"
                >
                  {t( item.description )}
                </Typography>
              </div>
            </div>
          ) )}
        </div>
        <Button size={"detail"}>
          <Link to={paths.techniques}>
            {t( "methods.btn" )}
          </Link>
        </Button>
      </div>
    </div>
  );
};
