import { Swiper } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "./Swiper.scss";

const SwiperContainer = ( { children } ) => (
  <Swiper
    pagination={true}
    navigation={true}
    modules={[ Pagination, Navigation ]}
    className="swiper_container"
  >
    {children}
  </Swiper>
);

export default SwiperContainer;
