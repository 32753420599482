import { TechniquesModule } from "@Modules/TechniquesModule";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";



export const Techniques = () => {
  const { t } = useTranslation( "navigation" );
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t( "techniques" )}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <TechniquesModule/>
    </main>
  );
};


