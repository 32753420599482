import { Typography } from "@Typography";
import classNames from "classnames";
import styles from "./Pagination.module.scss";
import Left from "../../Assets/Icons/nextPag.svg";
import Right from "../../Assets/Icons/prevPag.svg";

const Pagination = ( { count, fetchData, currentPage, itemCount } ) => {

  const paginate = ( page ) => {
    fetchData( page );
  };

  const nextPag = () => {
    if ( currentPage > 0 && currentPage < paginNum.length )  {
      fetchData( currentPage + 1 );
    }
  };

  const prevPag = () => {
    if ( currentPage > 1 ) {
      fetchData( currentPage - 1 );
    }
  };

  const paginNum = [];

  for ( let index = 1; index <= Math.ceil( count.count / itemCount ); index++ ) {
    paginNum.push( index );
  }

  return (
    <div className={styles.pagination}>
      <button 
        className={classNames(
          styles["btn"],
          { [styles["btn_d_active"]]: currentPage === 1 } 
        ) }  
        onClick={prevPag}
      >
        <img src={Left} alt="Prev" />
      </button>
      <ul className={styles.paginate}>
        {paginNum.map( ( num ) => (
          <li key={num} 
            onClick={() => paginate( num )}
            className={currentPage === num ? styles.active : styles.d_active}
          >
            <Typography variant="h6">{num}</Typography>
          </li>
        ) )}
      </ul>
      <button 
        className={classNames(
          styles["btn"],
          { [styles["btn_d_active"]]: currentPage === paginNum.length } 
        ) } 
        onClick={nextPag}
      >
        <img src={Right} alt="Next" />
      </button>
    </div>
  );
};
 
export default Pagination;