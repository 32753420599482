import styles from "./SliderGallery.module.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperContainer from "../../../../../Components/Swiper/Swiper";
import { useSliderGalleryStore } from "../store/useSliderGaleryStore";
import { Typography } from "@Typography";
import { SwiperSlide } from "swiper/react";
import { useEffect } from "react";
import useMediaQuery from "@Utils/hooks/useMediaQuery";
import alternatePhoto from "@Assets/Images/alternativePhoto.jpg";
import Loading from "@Ui/Loading/Loading";
import { useTranslation } from "react-i18next";

export const SliderGallery = () => {
  const { data, loading, getData } = useSliderGalleryStore();

  const isSmallScreen = useMediaQuery( "(max-width: 750px)" );
  const isMediumScreen = useMediaQuery( "(min-width: 751px) and (max-width: 1120px)" );

  const itemsPerSlide = isSmallScreen ? 1 : isMediumScreen ? 2 : 3;

  useEffect( () => {
    getData();
  }, [ getData ] );

  const galleryData = [];
  for ( let i = 0; i < data.length; i += itemsPerSlide ) {
    galleryData.push( data.slice( i, i + itemsPerSlide ) );
  }
  const { t } = useTranslation( "homepage" );

  if ( loading ) return <Loading/>;

  const handleImageError = ( event ) => {
    event.target.src = alternatePhoto;
  };

  return (
    <div className={styles.content}>
      <div className="Container">
        <Typography
          variant="h1"
          weight="medium"
          color="DarkBlue"
          className={styles.title}
        >
          {t( "gallery" )}
        </Typography>
        <SwiperContainer>
          {galleryData &&
            galleryData.map( ( item, index ) => (
              <SwiperSlide key={index}>
                <div className={styles.fotos}>
                  {item.map( ( item, idx ) => (
                    <div key={idx} className={styles.swiperPhoto}>
                      <img
                        src={item.image || alternatePhoto}
                        alt="Фото"
                        onError={handleImageError}
                      />
                    </div>
                  ) )}
                </div>
              </SwiperSlide>
            ) )}
        </SwiperContainer>
      </div>
    </div>
  );
};
