export const listItems = [
  {
    title: "toKid.reason1",
    description: "toKid.subtitle1"
  },{
    title: "toKid.reason2",
    description: "toKid.subtitle2"
  },{
    title: "toKid.reason3",
    description: "toKid.subtitle3"
  }
];