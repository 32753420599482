import styles from "./Garden.module.scss";
import Button from "@Ui/Buttons/Button";
import GardenPhot from "@Assets/Images/garden.webp";
import { useGardenStore } from "./store/useGardenStore";
import { Typography } from "@Typography";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@Utils/hooks/useMediaQuery";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import alternatePhoto from "@Assets/Images/alternativePhoto.jpg";

export const Garden = () => {
  const { data, getData } = useGardenStore();
  const socialData = data && data[0];
  const gardenRef = useRef();
  const { t, i18n } = useTranslation( "homepage" );
  const isTablet = useMediaQuery( "(max-width: 1250px)" );
  const isMobile = useMediaQuery( "(max-width: 768px)" );
  const location = useLocation();

  useEffect( () => {
    window.gardenRef = gardenRef;
    getData();
  }, [ getData ] );

  useEffect( () => {
    if ( location.state?.scrollToGarden ) {
      if ( gardenRef && gardenRef.current ) {
        gardenRef.current.scrollIntoView( { behavior: "smooth" } );
      }
    }
  }, [ location.state ] );

  const handleImageError = ( event ) => {
    event.target.src = alternatePhoto;
  };

  const truncateValue = isTablet
    ? i18n.language === "ru"
      ? 211
      : i18n.language === "ky"
        ? 211
        : false
    : isMobile ? 
      false 
      : false;

  const truncatedText = ( text, limit ) => {
    if ( limit && text.length > limit ) {
      return text.substring( 0, limit ) + ".";
    }
    return text;
  };



  return (
    <div className={classNames( styles.garden, "Container" )} ref={gardenRef}>
      <div className={styles.content}>
        <div className={styles.photo}>
          <img
            src={GardenPhot || alternatePhoto}
            alt="Фотография садика" 
            onError={handleImageError}
          />
        </div>
        <div className={styles.content_text}>
          <div className={styles.text}>
            <Typography variant="h4" weight="medium" className={styles.titleName}>
              {parse( t( "garden.title" ) )}
            </Typography>
            <Typography variant="body" weight="regular" truncate={false}>
              {truncateValue
                ? truncatedText( t( "garden.description" ), truncateValue )
                : t( "garden.description" )}
            </Typography>

          </div>
          <Button href={socialData?.kindergarten_detail} target={"_blank"}>
            {t( "garden.button" )}
          </Button>
        </div>
      </div>
    </div>
  );
};
