import { Api } from "@Utils/API/Api";
import { create } from "zustand";

export const storeOurBlog = create( ( set ) => ( {
  blogData: [],
  loading: false,
  error: "",
  nextPageURL: "",
  previousPageURL: "",
  currentPage: 1,

  fetchBlog: async( page = 1 ) => {
    set( { loading: true } );
    try {
      const { data } = await Api.get( `blogs/?page=${page}` );
      set( {
        blogData: data,
        nextPageURL: await data.next,
        previousPageURL: await data.previous,
        currentPage: page,
        loading: false
      } );
    } catch ( error ) {
      set( { error: error } );
    }
  }
} ) );