import styles from "./ToKid.module.scss";
import { Typography } from "@Ui/Typography/Typography";
import picture from "@Assets/Images/toKid.webp";
import { listItems } from "../constants/constants";
import { AnimationBox } from "@Ui/AnimationBox/AnimationBox";
import alternatePhoto from "@Assets/Images/alternativePhoto.jpg";
import { useTranslation } from "react-i18next";


export const ToKid = () => {
  const { t } = useTranslation( "homepage" );

  const handleImageError = ( event ) => {
    event.target.src = alternatePhoto;
  };

  return (
    <div className="Container">
      <div className={styles.allBlock}>
        <div className={styles.block}>
          <div className={styles.blockText}>
            <Typography
              variant="h4" 
              weight="semibold"
              color="blue" 
            >
              {t( "toKid.title" )}
            </Typography>
            <Typography
              variant="bodyExtraSmall"
              weight="medium" 
              color="Grey" 
              className={styles.text}
            >
              {t( "toKid.kidsKind" )}
            </Typography>
          </div>
          <div className={styles.blockText2}>
            <div className={styles.tabs}>
              {listItems.map( ( item , index ) => (
                <AnimationBox 
                  delay={index * 0.1}
                  className={styles.tab}
                  key={index}>
                  <div className={styles.tabText}>
                    <Typography
                      variant="bodySmall"
                      weight="medium" 
                      color="black" 
                    >
                      {t( item.title )}
                    </Typography>
                    <Typography
                      variant="h6"
                      color="black" >
                      {t( item.description )}
                    </Typography>
                  </div>
                </AnimationBox>
              ) )}
            </div>
          </div>
        </div>
        <div className={styles.picture}>
          <img
            src={picture || alternatePhoto}
            onError={handleImageError}
            alt="photo" />
        </div>
      </div>
    </div>
  );
};
