// страница "ошибка 404" 

import { NotFound } from "@Modules/NotFounfModule/NotFound";
import { Helmet } from "react-helmet";

export const NotFoundPage = () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>404</title>
      <link rel="canonical" href="http://mysite.com/example" />
    </Helmet>
    <NotFound/>
  </div>
);