import { useEffect, useState } from "react";


const useMediaQuery = ( query ) => {

  const getMatches = ( query ) => window.matchMedia( query ).matches;

  const [ matches, setMatches ] = useState( getMatches( query ) );

  const handleChange = () => {
    setMatches( getMatches( query ) );
  };


  useEffect( () => {
    const matchMedia = window.matchMedia( query );
    handleChange();
    if ( matchMedia.addListener ) {
      matchMedia.addListener( handleChange );
    } else {
      matchMedia.addEventListener( "change", handleChange );
    }

    return () => {
      if ( matchMedia.removeListener ) {
        matchMedia.removeListener( handleChange );
      } else {
        matchMedia.removeEventListener( "change", handleChange );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ query ] );

  return matches;
};

export default useMediaQuery;