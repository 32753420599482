import { Api } from "@Utils/API/Api";
import { create } from "zustand";

export const storeCatalog = create( ( set ) => ( {
  loading: false,
  error: "",
  products: [],
  nextPageURL: "",
  currentPage: 1,
  sortData: [],

  fetchData: async( page = 1 ) => {
    set( { loading: true } );
    try {
      const { data } = await Api.get( `products/?page=${page}` );
      
      set ( { 
        products: await data, 
        nextPageURL: await data.next,
        currentPage: page,
        loading: false 
      } );
    } catch ( err ) {
      set( { error: err.message,  loading: false } );
    }
  },
  
  fetchDataSort: async( subCategories = [] ) => {
    set( { loading: true } );
    try {
      let combinedResults = [];

      for ( const subCategory of subCategories ) {
        const response = await Api.get( `products/?subcategory=${subCategory.category} ` );
        combinedResults = combinedResults.concat( response.data.results );
      }
      set( { 
        sortData: combinedResults,
        next: null, 
        loading: false 
      } );
    } catch ( err ) {
      set( { error: err.message,  loading: false } );
    }
  }

} ) );