import { useState, useRef } from "react";
import { useClickOutSide } from "@Utils/hooks/useClickOutSide";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { motion } from "framer-motion";

import { HeaderNavLink, paths } from "@Utils/Constans/constants";
import { Typography } from "@Typography";
import { SocialIcons }from "@Components/SocialIcons/SocialIcons.jsx";
import LanguageSelect from "@Modules/Header/components/LanguageSelector/LanguageSelector";
import styles from "./Header.module.scss";

import Logo from "@Assets/Icons/logo.svg";
import Burger from "@Assets/Icons/Burger.svg";
import BurgerClose from "@Assets/Icons/BurgerX.svg";

export const Header = () =>  {

  const [ isOpen, setIsOpen ] = useState( false );
  
  const miniHeaderRef = useRef( null );

  const { t } = useTranslation( "navigation" );

  const handleOpen = ( event ) => {
    event.stopPropagation();
    setIsOpen( !isOpen );
  };

  const slideVerticalAnimation = {
    open: {
      rotateX: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
      display: "block"
    },
    close: {
      rotateX: -15,
      y: -20,
      opacity: 0,
      transition: {
        duration: 0.3
      },
      transitionEnd: {
        display: "none"
      }
    }
  };

  useClickOutSide( miniHeaderRef, () => {setIsOpen( false );} );

  return (
    <header className={styles.header}>
      <div className={styles.headerSpace}/>
      <div className="Container">
        <nav className={styles.nav} ref={miniHeaderRef}>
          <div className={styles.logo}>
            <NavLink to={"/"}>
              <img src={Logo} alt="Логотип"  onClick={() => setIsOpen( false )}/>
            </NavLink>
          </div>
          <div className={styles.nav_list}>
            <div className={styles.list}>
              <ul>
                {HeaderNavLink.map( ( el, i ) => (
                  <li key={i}>
                    <Typography variant="h6" weight="regular">
                      {el.url === paths.contacts ? (
                        <HashLink 
                          smooth
                          to="#maps"
                          className={( { isActive } ) => ( isActive ? styles.active : "" )}
                        >
                          {t( el.label )}
                        </HashLink>
                      ) : (
                        <NavLink 
                          to={el.url}
                          className={( { isActive } ) => ( isActive ? styles.active : "" )}
                        >
                          {t( el.label )}
                        </NavLink>
                      )}
                    </Typography>
                  </li>
                ) )}
              </ul>
            </div>
            <div className={styles.language}>
              <LanguageSelect />
            </div>
            <div className={styles.burger} onClick={handleOpen}>
              <img src={isOpen ? BurgerClose : Burger} alt="Menu" />
            </div>
          </div>
          {isOpen && (
            <motion.div
              className={styles.mini_header}
              initial="close"animate={isOpen ? "open" : "close"}
              variants={slideVerticalAnimation}
            >
              <div className={styles.mini_list}>
                <ul>
                  {HeaderNavLink.map( ( el, i ) => (
                    <li key={i} onClick={handleOpen}>
                      <Typography variant="h6">
                        {el.url === paths.contacts ? (
                          <HashLink 
                            smooth
                            to="#maps"
                            className={( { isActive } ) => ( isActive ? styles.active : "" )}
                          >
                            {t( el.label )}
                          </HashLink>
                        ) : (
                          <NavLink 
                            to={el.url}
                            className={( { isActive } ) => ( isActive ? styles.active : "" )}
                          >
                            {t( el.label )}
                          </NavLink>
                        )}
                      </Typography>
                    </li>
                  ) )}
                </ul>
                <SocialIcons 
                  className={styles.social_icons}  
                  position="relative"
                  flexDirection="row" 
                />
              </div>
            </motion.div>
          )}
        </nav>
      </div>
    </header>
  );
};

