import styles from "./Schedule.module.scss";
import { ReviewsSwiper } from "@Components/ReviewsSwiper/ReviewsSwiper";
import { GardenSchedule,SchedulesCard  } from "@Modules/SchedulePageModule";
export const Schedule = () => 

  (
    <div className="Container">
      <div className={styles.Schedule}>
        
        <div className={styles.ScheduleCard}>
          <SchedulesCard />
        </div>
        <div className={styles.GardenSchedule}></div>
        <GardenSchedule />
        <div className={styles.Reviews}>
          <ReviewsSwiper />
        </div>
      </div>
    </div>
  )
;
