import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Header }from "@Modules/Header/Header";
import { SocialIcons }from "@Components/SocialIcons/SocialIcons";
import ScrollToTopButton from "@Components/ScrollToTopButton/ScrollToTopButton";
import Loading from "@Ui/Loading/Loading";
import { Footer } from "@Modules/Footer/components/FooterBlock/Footer";
import ScrollToTop from "@Utils/helpers/ScrollToTop";
import { Maps } from "@Components/Map";



export const Layout = () => (
  <>
    <ScrollToTop/>
    <Header/>
    <Suspense fallback={<Loading/>} >
      <ScrollToTopButton/>
      <SocialIcons
        position="fixed"
        zIndex="999999"
      /> 
      <Outlet/>
      <Maps/>
    </Suspense>
    <Footer/>
  </>
);
