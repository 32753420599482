import vector1 from "@Assets/Icons/vector1.svg";
import vector2 from "@Assets/Icons/vector2.svg";
import vector3 from "@Assets/Icons/vector3.svg";
import vector4 from "@Assets/Icons/vector4.svg";

export const numbersList = [
  {
    icon: `${vector1}`,
    title: "weNumbersHome.employee",
    target: 25,
    duration: 1000, 
    suffix: "+", 
  },
  {
    icon: `${vector2}`,
    title: "weNumbersHome.experience",
    target: 13,
    duration: 1000,
    suffix: "+",
  },
  {
    icon: `${vector4}`,
    title: "weNumbersHome.kids",
    target: 99,
    duration: 1500,
    suffix: "%",
  },
  {
    icon: `${vector3}`,
    title: "weNumbersHome.effectiveClasses",
    target: 95,
    duration: 1500,
    suffix: "%",
  },
 
];
