import { Typography } from "@Typography";
import styles from "./CheckBox.module.scss";
// import checkIcon from "../../Assets/Icons/check.svg";

const CheckBox = ( { children, checked, onChange } ) => {
  console.log();
  return (
    <label className={styles.label}>
      <input type="checkbox" className={styles.checkbox} checked={checked} onChange={onChange} />
      <span className={styles.customCheckbox}></span>
      <Typography variant="body">{children}</Typography>
    </label>
  );
};

export default CheckBox;