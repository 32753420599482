export const BASE_URL = import.meta.env.VITE_API_URL;

export const COPYRIGHT = "https://geeks.kg/geeks-pro";

export const paths =  {
  home: "/",
  notFound: "/*",
  direction: "/direction",
  costOfClasses: "/costofclasses",
  catalog: "/catalog",
  ourblog: "/ourblog",
  programs: "/programs",
  schedule: "/schedule",
  contacts: "/contacts",
  techniques: "/techniques",
};

export const HeaderNavLink = [
  { url: paths.direction, label: "direction" },
  { url: paths.ourblog, label: "our_blog" },
  { url: paths.costOfClasses, label: "prices" },
  { url: paths.schedule, label: "schedule" },
  { url: paths.programs, label: "program" },
  { url: paths.catalog, label: "catalog" },
  { url: paths.contacts, label: "contacts" },
];