import { create } from "zustand";
import { Api } from "@Utils/API/Api";

export const reviwsStore = create( ( set ) => ( {
  reviws: [],
  
  fetchReviws: async() => {
    try {
      const response = await Api.get( "reviews" );
      set( { reviws: await response.data } );
    } catch ( error ) {
      console.error( error.massege );
    }
  }
} ) );