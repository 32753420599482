import { RouterProvider } from "react-router-dom";
import { Router } from "./Routes/Routes";


const App = () => (
  <>
    <RouterProvider router={Router}/>
  </>
);

export default App;