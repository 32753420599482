 
import styles from "./OurMission.module.scss";
import Planet from "@Assets/Icons/Planet.svg";
import Pen from "@Assets/Icons/Pen.svg";
import { Typography } from "@Typography";
import { useTranslation } from "react-i18next";
import alternatePhoto from "@Assets/Images/alternativePhoto.jpg";


export const OurMission = () => {
  const { t } = useTranslation( "homepage" );
  const handleImageError = ( event ) => {
    event.target.src = alternatePhoto;
  };
  return (
    <div className="Container">
      <div className={styles.content}>
        <div className={styles.title}>
          <Typography variant="h1" weight="medium" color="DarkBlue">
            {t( "ourMission.title" )}
          </Typography>
        </div>
        <img src={Planet || alternatePhoto} alt="Планета" className={styles.icon_planet} onError={handleImageError}/>
        <img src={Pen || alternatePhoto} alt="Карандаш с кубиками" className={styles.icon_pen} onError={handleImageError}/>
        <div className={styles.cards}>
          <div className={styles.card_yellow}>
            <Typography variant="bodySmall">
              {t( "ourMission.description1" )}
            </Typography>
          </div>
          <div className={styles.card_green}>
            <Typography variant="bodySmall">
              {t( "ourMission.description2" )}
            </Typography>
          </div>
          <div className={styles.card_violet}>
            <Typography variant="bodySmall">
              {t( "ourMission.description3" )}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
