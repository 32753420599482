import{ HelpForKidsItem }from "@Modules/HomePageModule/components/HelpForKidsBlock/HelpForKidsItem/HelpForKidsItem";
import { Typography } from "@Ui/Typography/Typography";
import styles from "./HelpForKids.module.scss";
import classNames from "classnames";
import { AnimationBox }from "@Ui/AnimationBox/AnimationBox";
import { helpForKidsData } from "../helpForKidsData";
import { useTranslation } from "react-i18next";

export const HelpForKids = () => {
  const { t } = useTranslation( "homepage" );
  return (
    <section className={classNames( styles.helpForKids, "Container" )}>
      <div className={styles.helpForKidsBox}>
        <Typography 
          variant="h1"
          weight="medium"
          color="DarkBlue"
          className={styles.title}
        >
          {t( "help_kids.title" )}
        </Typography>
        <div className={styles.item}>
          {helpForKidsData.map( ( item, index ) => (
            <AnimationBox
              key={index}
              delay={index * 0.1}
            >
              <HelpForKidsItem
                title={item.title}
                description={item.description}
                background={item.background}
                index={index + 1}
                bgcIndex={item.bgcIndex}
              />
            </AnimationBox>
          ) )}
        </div>
      </div>
    </section>
  );
};
