import { create } from "zustand";
import { Api } from "@Utils/API/Api";

const useDirectionsStore = create( ( set ) => ( {
  directions: [],
  isLoading: false,
  error: null,
  modalContent: { title: "", description: "" },
  isModalOpen: false,

  fetchDirections: async() => {
    set( { isLoading: true, error: null } );

    try {
      const response = await Api.get( "/directions/" );
      set( { directions: response.data, isLoading: false } );
    } catch ( error ) {
      set( { error: "Ошибка при загрузке направлений", isLoading: false } );
    }
  },

  openModal: ( direction ) => set( { modalContent: direction, isModalOpen: true } ),

  closeModal: () =>
    set( { isModalOpen: false, modalContent: { title: "", description: "" } } ),
} ) );

export default useDirectionsStore;
