import { useRef, useState, useEffect } from "react";
import styles from "./SchedulesCard.module.scss";
import { Typography } from "@Typography";
import Button from "@Ui/Buttons/Button";
import { ModalScheduleCard } from "../ModalScheduleCard/ModalScheduleCard";
import { useScheduleStore } from "@Modules/SchedulePageModule/store/storeSchedule";
import { useClickOutSide } from "@Utils/hooks/useClickOutSide";
import Loading from "@Ui/Loading/Loading";
import useMediaQuery from "@Utils/hooks/useMediaQuery";
import AltImage from "@Assets/Images/alternativePhoto.jpg";
import { useTranslation } from "react-i18next";

export const SchedulesCard = () => {
  const [ isOpen, setIsOpen ] = useState( false );
  const [ selectedDiplom, setSelectedDiplom ] = useState( "" );
  const modalRef = useRef( null );
  const cardRefs = useRef( [] );

  const { schedule, getData, loading } = useScheduleStore();

  const isMobile = useMediaQuery( "(max-width: 768px)" );

  const openModal = ( diplom ) => {
    setSelectedDiplom( diplom );
    setIsOpen( true );
  };

  const closeModal = () => {
    setIsOpen( false );
    setSelectedDiplom( "" );
  };

  useClickOutSide( modalRef, closeModal );

  useEffect( () => {
    getData();
  }, [ getData ] );


  const { t } = useTranslation( "schedulepage" );

  if ( loading ) {
    return <Loading />;
  }

  return (
    <div className={styles.Container}>
      <Typography variant="h1" weight="medium" color="DarkBlue" className={styles.hl}>
        {t( "schedule_card.title" )}
      </Typography>
      {schedule.map(
        (
          {
            photo,
            title,
            experience,
            professions,
            work_schedule,
            education,
            sign_up_urls,
          },
          i
        ) => (
          
          <div
            key={i}
            className={`${styles.ScheduleCard} ${
              isMobile ? styles.mobileCard : ""
            }`}
            ref={( el ) => ( cardRefs.current[i] = el )}
          >
           
            <ModalScheduleCard isOpen={isOpen} closeModal={closeModal}>
              <div ref={modalRef} className={styles.diploma}>
                {selectedDiplom && (
                  <img
                    src={selectedDiplom || AltImage}
                    alt="Образование"
                    className={styles.diplomaImg}
                  />
                )}
              </div>
            </ModalScheduleCard>
            {isMobile }
            <div className={styles.photo}>
              <img
                className={styles.FotoTeacher}
                src={photo || AltImage}
                alt="преподаватель"
              />
            </div>
            <div className={styles.divider}>
              <div className={styles.ScheduleCardContent}>
                <div className={styles.Fio_experience}>
                  <div className={styles.fio}>
                    <Typography variant="body" weight="medium">
                      <span>{title}</span>
                    </Typography>
                  </div>
                  <div className={styles.experience}>
                    <Typography variant="body" weight="medium">
                      <span>{t( "schedule_card.seniority" )}:&nbsp;</span>
                    </Typography>
                    <Typography className={styles.texts}>{experience}</Typography>
                  </div>
                </div>
                <div className={styles.InfoTeacher}>
                  <div className={styles.possessions}>
                    <Typography weight="body" color="blue">{t( "schedule_card.specialties" )}:</Typography>
                    <Typography  className={styles.texts}
                    >
                      <ul>
                        {professions.map( ( { name }, index ) => (
                          <li key={index}>{name}</li>
                        ) )}
                      </ul>
                    </Typography>
                  </div>
                  <div className={styles.schedules}>
                    <Typography variant="body" weight="medium"><span>{t( "schedule_card.title" )}:</span></Typography>
                    {work_schedule.map(
                      ( { day, start_time, end_time }, index ) => (
                        <Typography
                          key={index}
                          className={styles.texts}
                        >
                          {day} : {start_time} - {end_time}
                        </Typography>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.buttons}>
                <Button
                  size="medium"
                  color="transparent"
                  onClick={() => openModal( education )}
                >
                  <p className={styles.buttonText}>{t( "schedule_card.modalbutton" )}</p> 
                </Button>
                <Button size="medium" href={sign_up_urls} target="_blank">
                  <p className={styles.buttonText}>{t( "schedule_card.text_button" )}</p>
                </Button>
              </div>
              
              
            </div>
          </div>
        )
      )}
    </div>
  );
};
