import { useEffect, useMemo, useRef } from "react";
import { useClickOutSide } from "@Utils/hooks/useClickOutSide";
import useMediaQuery from "@Utils/hooks/useMediaQuery";
import { storeCatalogCategory } from "./store/StoreCatalogCategory";
import { storeCatalog } from "@Modules/CatalogProductsModule/store/StoreCatalog";
import { Typography } from "@Typography";
import CheckBox from "@Ui/CheckBox/CheckBox";
import styles from "./ProductCategory.module.scss";
import CloseModal from "@Assets/Icons/closeMod.svg";


const ProductCategory = ( { selectedCategories, onCategoryChange, handleOpen } ) => {

  const { 
    categoriesData,
    subCategoryData,
    fetchCategory,
    fetchSubCategory
  } = storeCatalogCategory();
  const products = storeCatalog( ( state ) => state.products );

  const modalRef = useRef( null );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useMediaQuery( "(max-width: 768px)" ) && useClickOutSide( modalRef, () => {handleClose();} );

  const availableSubCategories = useMemo( () => {
    const subCategoriesInProducts = new Set( products.results.map( ( elem ) => elem.subcategory.id ) );
    return subCategoryData.filter( ( elem ) => subCategoriesInProducts.has( elem.id ) );
  }, [ products, subCategoryData ] );

  const onCheckboxChange = ( id, category, isChecked ) => {
    onCategoryChange( id, category, isChecked );
  };

  const handleClose = () => {
    handleOpen();
  };

  useEffect( () => {
    fetchCategory();
    fetchSubCategory();
  }, [ fetchCategory, fetchSubCategory ] );

  return (
    <div className={styles.modal}>
      <div className={styles.category} ref={modalRef}>
        <div className={styles.close} onClick={handleClose}>
          <img src={CloseModal} alt="CloseModal" />
        </div>
        {categoriesData && categoriesData.map( ( category ) => (
          <div key={category.id} className={styles.categorySection}>
            <Typography variant="bigBody" weight="medium" className={styles.category_name}>
              {category.name}
            </Typography>
            <ul>
              {availableSubCategories
                .filter( ( subCategory ) => subCategory.category.id === category.id )
                .map( ( subCategory ) => (
                  <li key={subCategory.id} className={styles.subCategory_name}>
                    <CheckBox
                      checked={selectedCategories.some( ( cat ) => cat.id === subCategory.id )}
                      onChange={( e ) => onCheckboxChange( subCategory.id, subCategory.name, e.target.checked )}
                    >
                      {subCategory.name}
                    </CheckBox>
                  </li>
                ) )}
            </ul>
          </div>
        ) )}
      </div>
    </div>
  );
};

export default ProductCategory;