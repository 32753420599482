import styles from "./Modal.module.scss";
import classNames from "classnames";
import closeMod from "@Assets/Icons/closeMod.svg";
import { AnimationBox } from "@Ui/AnimationBox/AnimationBox";
import { useEffect } from "react";
import alternatePhoto from "@Assets/Images/alternativePhoto.jpg";

export const Modal = ( { isOpen, onClose, contentType, children } ) => {
  useEffect( () => {
    if ( isOpen ) {
      const handleScroll = ( event ) => {
        event.preventDefault();
      };

      window.addEventListener( "scroll", handleScroll );
      document.body.style.overflow = "hidden";

      return () => {
        window.removeEventListener( "scroll", handleScroll );
        document.body.style.overflow = "auto";
      };
    }
  }, [ isOpen ] );

  const handleImageError = ( event ) => {
    event.target.src = alternatePhoto;
  };

  if ( !isOpen ) return null;

  return (
    <div
      className={classNames( styles.modalOverlay, {
        [styles.modalOpen]: isOpen,
      } )}
      onClick={onClose}
    >
      <div
        className={classNames( styles.modalContent, {
          [styles.modalContentImage]: contentType === "image",
          [styles.modalContentVideo]: contentType === "video",
        } )}
        onClick={( e ) => e.stopPropagation()}
      >
        <div className={styles.closeButton} onClick={onClose}>
          <img src={closeMod || alternatePhoto} alt="close" onError={handleImageError}/>
        </div>
        <AnimationBox animationType="fadeIn" className={styles.modalBody}>
          {children}
        </AnimationBox>
      </div>
    </div>
  );
};
