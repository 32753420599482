import { create } from "zustand";
import { Api } from "@Utils/API/Api";

export const useProgramsStore = create( ( set ) => ( {
  programs: [],
  isLoading: false,
  error: null,

  fetchprograms: async() => {
    set( { isLoading: true, error: null } );
    try {
      const response = await Api.get( "programs" );
      set( { programs: response.data, isLoading: false } );
    } catch ( error ) {
      set( { error: error.message, isLoading: false } );
    }
  },
} ) );