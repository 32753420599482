import { create } from "zustand";
import { Api } from "@Utils/API/Api";

const useDirectionModalStore = create( ( set ) => ( {
  directions: [],
  isLoading: false,
  error: null,
  whatsappLink: "",

  fetchDirections: async() => {
    set( { isLoading: true, error: null } );

    try {
      const response = await Api.get( "/directions/" );
      set( { directions: response.data, isLoading: false } );
    } catch ( error ) {
      set( { error: "Ошибка при загрузке направлений", isLoading: false } );
    }
  },

  fetchWhatsAppLink: async( id ) => {
    try {
      const response = await Api.get( `/directions/${id}` );
      const whatsappLink = response.data.sign_up_urls;
      set( { whatsappLink } );
    } catch ( error ) {
      console.error( "Ошибка при получении ссылки WhatsApp:", error );
    }
  },

  openModal: ( direction ) => set( { modalContent: direction, isModalOpen: true } ),
  closeModal: () =>
    set( { isModalOpen: false, modalContent: { title: "", description: "" } } ),
} ) );

export default useDirectionModalStore;
