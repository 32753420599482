import { DirectionPage } from "@Modules/DirectionPageModule";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


export const Direction = () => {
  const { t } = useTranslation( "navigation" );
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t( "direction" )}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <DirectionPage/>
    </main>
  );
};