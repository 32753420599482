import styles from "./QuestionsItem.module.scss";
import classNames from "classnames";
import { Typography } from "@Ui/Typography/Typography";
import { useRef, useState, useEffect } from "react";
import alternatePhoto from "@Assets/Images/alternativePhoto.jpg";


export const QuestionsItem = ( props ) => {
  const { answer, question, icon, isOpen, onClick } = props;
  const answerWrapperRef = useRef( null );

  const [ maxHeight, setMaxHeight ] = useState( 0 );

  useEffect( () => {
    if ( isOpen && answerWrapperRef.current ) {
      setMaxHeight( answerWrapperRef.current.scrollHeight ); 
    } else {
      setMaxHeight( 0 );
    }
  }, [ isOpen ] );

  const handleImageError = ( event ) => {
    event.target.src = alternatePhoto;
  };

  return (
    <div className={styles.questionItem} onClick={onClick}>
      <div className={styles.questionHeader}>
        <Typography
          variant="bodySmall"
          weight="medium"
          color="black"
          className={styles.question}
        >
          {question}
        </Typography>
        <div className={isOpen ? classNames( styles.questionIcon, styles.open ) : styles.questionIcon}>
          <img
            src={icon} 
            alt="close mod icon"
            onError={handleImageError}
          />
        </div>
      </div>
      <div
        className={classNames( styles.answerWrapper, { [styles.answerOpen]: isOpen } )}
        ref={answerWrapperRef}
        style={{ maxHeight: `${maxHeight}px` }}
      >
        <Typography
          className={classNames( styles.answer, styles.question )}
          variant="bodySmall"
          weight="medium"
          color="black"
        >
          {answer}
        </Typography>
      </div>
    </div>
  );
};
