import { useEffect } from "react";
import { SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { reviwsStore } from "./store/storeReviws";
import { Typography } from "@Typography";
import SwiperContainer from "@Components/Swiper/Swiper";
import ReviwsSlider from "@Ui/ReviewsSlider/ReviwsSlider";
import styles from "./ReviewsSwiper.module.scss";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const ReviewsSwiper = () => {

  const { t } = useTranslation( "homepage" );

  const reviws = reviwsStore( ( state ) => state.reviws );
  const fetchReviws = reviwsStore( ( state ) => state.fetchReviws );

  useEffect( () => {
    fetchReviws();
  }, [ fetchReviws ] );

  return (
    <div className="Container">
      <div className={styles.content}>
        <Typography variant="h1" weight="medium" color="DarkBlue" className={styles.title}>{t( "reviews.reviews" )}</Typography>
        <SwiperContainer>
          {
            reviws.map( ( elem, i ) => (
              <SwiperSlide key={i}>
                <ReviwsSlider reviws={elem} />
              </SwiperSlide>
            ) )
          }
        </SwiperContainer>
      </div>
    </div>
  );
};

