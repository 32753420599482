import styles from "./TeamCard.module.scss";
import Button from "@Ui/Buttons/Button";
import { Modal } from "@Components/Modal/Modal";
import { YouTubePlayer }from "@Components/YouTubePlayer/YouTubePlayer";
import { Typography } from "@Typography";
import { useState } from "react";
import AltImage from "@Assets/Images/alternativePhoto.jpg";
import { useTranslation } from "react-i18next";


export const TeamCard = ( { title, photo, description, experience, video_urls, education } ) => {

  const [ isOpen, setIsOpen ] = useState( false );
  const [ player, setPlayer ] = useState( false );


  const openPlayerModal = () => setPlayer( true );
  const closePlayerModal = () => setPlayer( false );

  const openPhotoModal = () => setIsOpen( true );
  const closePhotorModal = () => setIsOpen( false );
  const { t } = useTranslation( "homepage" );

  const handleImageError = ( event ) => {
    event.target.src = AltImage;
  };

  return(
    <>
      <div className={styles.card}>
        <div className={styles.content}>
          <div className={styles.photo}>
            <img 
              src={photo || AltImage}
              alt="TeamPhoto" 
              onError={handleImageError}
            />
          </div>
          <div className={styles.card_text}>
            <Typography
              className={styles.name} 
              variant="h3"
              weight="medium"
            >
              {title}, { experience }
            </Typography>
            <Typography variant="h6" weight="regular">
              {description}
            </Typography>
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            size="medium"
            onClick={openPhotoModal}
          >
            {t( "ourTeam.education" )}
          </Button>
          <Button 
            size="medium"
            color={"transparent"} 
            onClick={openPlayerModal}
          >
            {t( "ourTeam.video" )}
          </Button>
        </div>
        <Modal isOpen={player} onClose={closePlayerModal} contentType="video">
          <div className={styles.playerVideo}>
            <YouTubePlayer videoUrl={video_urls || AltImage} />
          </div>
        </Modal>
        <Modal isOpen={isOpen} onClose={closePhotorModal} contentType="image">
          <div className={styles.modalImage}>
            <img src={education || AltImage} alt="education" />
          </div>
        </Modal>
      </div>
    </>
  );};
