import { create } from "zustand";
import { Api } from "@Utils/API/Api";

export const useScheduleStore = create( ( set ) => ( {
  schedule: [],
  loading: false,
  getData: async() => {
    set( { loading: true } );
    try {
      const { data } = await Api.get( "specialists" );
      set( { schedule: data, loading: false } );
    } catch ( error ) {
      set( { loading: false } );
    }
  },
} ) );