import { create } from "zustand";
import { Api } from "@Utils/API/Api";
export const storeCatalogCategory = create( ( set ) => ( {
  categoriesData: [],
  subCategoryData: [],
  loading: false,
  error: null,

  fetchCategory: async() => {
    set( { loading: true } );
    try {
      const { data } = await Api.get( "categories" );
      set( { categoriesData: await data, loading: false } );
    } catch ( err ) {
      set( { error: err.message, loading: false } );
    }
  },

  fetchSubCategory: async() => {
    set( { loading: true } );
    try {
      const { data } = await Api.get( "subcategories" );
      set( { subCategoryData: await data, loading: false } );
    } catch ( err ) {
      set( { error: err.message, loading: false } );
    }
  }
  
} ) );