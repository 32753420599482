import styles from "./DirectionModal.module.scss";
import { Typography } from "@Ui/Typography/Typography";
import HTMLReactParser from "html-react-parser";
import { useEffect } from "react";
import useDirectionModalStore from "./store/storeDirectionModal";
import { useTranslation } from "react-i18next";
import { useSocialStore } from "@Modules/HomePageModule/store/useSocialStore";

const DirectionModal = ( { title, description, id, onClose } ) => {
  const { t } = useTranslation( "directionmodal" );
  const { fetchWhatsAppLink } = useDirectionModalStore(); 
  const { data, getData } = useSocialStore();

  const whatsappData = data.find( ( item ) => item.urls.includes( "wa.me" ) );

  useEffect( () => {
    getData();
  }, [ getData ] );


  useEffect( () => {
    fetchWhatsAppLink( id );
  }, [ id, fetchWhatsAppLink ] );

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={( e ) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        <Typography variant="h1" className={styles.modalTitle}>{title}</Typography>
        <div className={styles.modalDescription}>
          <Typography variant="body">
            {HTMLReactParser( description )}
          </Typography>
        </div>
        <a href={whatsappData && whatsappData.urls} target="_blank" rel="noopener noreferrer" className={styles.modalButton}>
          {t( "directionmodal.btns" )}
        </a>
      </div>
    </div>
  );
};

export default DirectionModal;
