import WM1 from "@Assets/Images/Women1-1.webp";
import WM2 from "@Assets/Images/Women2-1.webp";
import WM3 from "@Assets/Images/Women3-1.webp";
import WM4 from "@Assets/Images/Women4-1.webp";



export const methodologyConstants = [
  {
    title: "methods.cardTitle1",
    description: "methods.text1",
    img: `${ WM1 }`,
    background: "#FFE7E5",
    color: "color1"
  },
  {
    title: "methods.cardTitle2",
    description: "methods.text2",
    img: `${ WM2 }`,
    background: "#F2FAFD",
    color: "color2"
  }
  ,
  {
    title: "methods.cardTitle3",
    description: "methods.text3",
    img: `${ WM3 }`,
    background: "#F7EAC7",
    color: "color3"
  },
  {
    title: "methods.cardTitle4",
    description: "methods.text4",
    img: `${ WM4 }`,
    background: "#F2FAFD",
    color: "color2"
  }
];