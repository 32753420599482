import styles from "./Footer.module.scss";
import Logo from "@Assets/Icons/logo.svg";
import { SocialIcons } from "@Components/SocialIcons/SocialIcons";
import { Typography } from "@Ui/Typography/Typography";
import { footerNavLinks } from "../../constants/constants";
import { FooterDownBlock } from "../FooterDownBlock/FooterDownBlock";
import classNames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const handleGardenClick = ( event ) => {
    event.preventDefault();
    const gardenRef = window.gardenRef;

    if ( location.pathname === "/" ) {
      if ( gardenRef && gardenRef.current ) {
        gardenRef.current.scrollIntoView( { behavior: "smooth" } );
      }
    } else {
      navigate( "/", { state: { scrollToGarden: true } } );
    }
  };

  const { t } = useTranslation( "homepage" );

  return (
    <footer className={styles.footer}>
      <div className={classNames( styles.container, "Container" )}>
        <div className={styles.footerBlocks}>
          <div className={styles.footerBlockLeft}>
            <div className={styles.footerLogoText}>
              <div className={styles.footerLogo}>
                <img src={Logo} alt="Logo Profi" />
              </div>
              <div className={styles.footerText}>
                <Typography weight="semibold" color="DarkBlue" variant="body">
                  Logoprofi.Kg
                </Typography>
                <Typography variant="h6" weight="medium">
                  {t( "footer.footerTitle" )}
                </Typography>
              </div>
            </div>
            <SocialIcons
              className={styles.social}
              position="relative"
              flexDirection="row"
              top="0"
              zIndex="1"
            />
          </div>
          <div className={styles.footerBlockRight}>
            {footerNavLinks.map( ( item, index ) => (
              <div key={index} className={`${styles.footerLinks} ${index === 0 ? styles.first : ""}`}>
                <div>
                  <Typography variant="bodySmall" color="DarkBlue" weight="medium">
                    {t( item.title )}
                  </Typography>
                </div>
                <div className={styles.footerLinksList}>
                  {item.links.map( ( link, index ) => (
                    <div key={index}>
                      {link.title === "footer.kinderGarden" ? (
                        <a href="/" onClick={handleGardenClick}>
                          <Typography variant="h6" color="Black" weight="regular">
                            {t( link.title )}
                          </Typography>
                        </a>
                      ) : (
                        <a href={link.href}>
                          <Typography variant="h6" color="Black" weight="regular">
                            {t( link.title )}
                          </Typography>
                        </a>
                      )}
                    </div>
                  ) )}
                </div>
              </div>
            ) )}
          </div>
        </div>
      </div>
      <FooterDownBlock />
    </footer>
  );
};

