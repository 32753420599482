import styles from "./NotFound.module.scss";
import { Typography } from "@Ui/Typography/Typography";
import Button from "@Ui/Buttons/Button";

export const NotFound = () => (
  <main className={styles.notFound}>
    <div className={styles.notFoundText}>
      <p className={styles.not}>404</p>
      <div>
        <Typography weight="medium" className={styles.notFoundTitle}>
        Страница не найдена
        </Typography>
        <Button href={"/"} size={"large"}>
          <Typography weight="medium" variant="bodyExtraSmall">
          Перейти на главную страницу
          </Typography>
        </Button>
      </div>
    </div>
  </main>
);
