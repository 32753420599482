import { Typography } from "@Ui/Typography/Typography";
import styles from "./HelpForKidsItem.module.scss";
import { useTranslation } from "react-i18next";

export const HelpForKidsItem = ( props ) => {
  const { 
    title,
    index,
    background,
    bgcIndex
  } = props;

  const { t } = useTranslation( "homepage" );

  return (
    <div
      style={{ backgroundColor: background }}
      className={styles.helpForKidsItem}
    >
      <div className={styles.title}>
        <div
          className={styles.titleIndex}
          style={{ backgroundColor: bgcIndex }}
        >
          <Typography
            variant="bigBody"
            weight="medium"
            color="white"
          >
            {index}
          </Typography>
        </div>
        <Typography
          variant="body"
          weight="medium"
          color="black" 
          className={styles.p}
        >
          { t( title ) }
        </Typography>
      </div>
    </div>
  );
};

