import styles from "./CostItems.module.scss";
import classNames from "classnames";
import { CostItem } from "../CostItem/CostItem";
import { useCostStore } from "@Modules/PricePageModule/store/useCostStore";
import { useCallback, useEffect } from "react";
import { Typography } from "@Ui/Typography/Typography";
import Loading from "@Ui/Loading/Loading";
import { useTranslation } from "react-i18next";


export const CostItems = () => {
  const { data, getData,loading } = useCostStore();
  const { t } = useTranslation( "homepage" );
  const groupedData = data.reduce( ( acc, item ) => {
    if ( !acc[item.session_category] ) {
      acc[item.session_category] = [];
    }
    acc[item.session_category].push( item );
    return acc;
  }, {} );

  const fetchData = useCallback( () => {
    getData();
  }, [ getData ] );

  useEffect( () => {
    fetchData();
  }, [ fetchData ] );

  if( loading ){
    return <Loading />;
  }

  return(
    <section className={classNames( styles.costItems, "Container" )}>
      <Typography
        variant="h1"
        weight="medium"
        className={styles.costItemsTitle}
      >
        {t( "footer.prices" )}
      </Typography>
      <div className={styles.costItemsList}>
        {Object.keys( groupedData ).map( ( category, index ) => (
          <CostItem
            time={index}
            key={category}
            category={category}
            sessions={groupedData[category]}
          />
        ) )}
      </div>
    </section>
  );};
