import { Api } from "@Utils/API/Api";
import { create } from "zustand";


export const useSliderGalleryStore = create( ( set ) => ( {
  data: [],
  loading: false,
  getData: async() => {
    set( { loading: true } );
    try {
      const { data } = await Api.get( "galleries" );
      set( { data: data, loading: false } );
    } catch ( error ) {
      set( { loading: false } );
    }
  },
} ) );
