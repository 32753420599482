import styles from "./GardenSchedule.module.scss";
import { Typography } from "@Ui/Typography/Typography";
import glitter1 from "@Assets/Icons/glitter.svg";
import glitter2 from "@Assets/Icons/glitter2.svg";
import Clock from "@Assets/Images/IconClock.png";
import abc from "@Assets/Images/ABC.png";
import Button from "@Ui/Buttons/Button";
import { useEffect } from "react";
import { useGardenStore }from "@Modules/HomePageModule/components/GardenBlock/store/useGardenStore";
import useMediaQuery from "@Utils/hooks/useMediaQuery";
import { useTranslation } from "react-i18next";


export const GardenSchedule = () => {
  const { data, getData } = useGardenStore();
  const socialData = data && data[0];

  useEffect( () => {
    getData();
  }, [ getData ] );

  const isDesktop = useMediaQuery( "(min-width: 769px)" );

  const { t } = useTranslation( "schedulepage" );


  return (
    <div className={styles.GardenSchedule}>
      <Typography variant="h1" weight="medium" color="DarkBlue" className={styles.h1}>
        {t( "garden_schedule.title" )}
      </Typography>
      <div className={styles.text}>
        <Typography variant="body" color="black" className={styles.textGarden}>
          {t( "garden_schedule.text" )}
        </Typography>
      </div>
      <div className={styles.block}>
        <img src={glitter1} alt="glitter" className={styles.glitter1} />
        <img src={Clock} alt="IconClock" className={styles.IconClock} />
        {isDesktop && <img src={glitter2} alt="glitter" className={styles.glitter2} />}
        <img src={abc} alt="ABC" className={styles.abc} />
        <div className={styles.h3text}>
          <Typography variant="h3" weight="medium" color="Azureblue" className={styles.h3}>
            {t( "garden_schedule.time_text" )}:
          </Typography>
        </div>
        <div className={styles.days}>
          <div className={styles.worker}>
            <Typography variant="bigBody" className={styles.daysText}>{t( "garden_schedule.working_days" )} </Typography>
            <Typography variant="bigBody" weight="medium"className={styles.daysOff} >9:00-19:00</Typography>
          </div>
          <div className={styles.DayOff}>
            <Typography variant="bigBody" className={styles.daysText}>{t( "garden_schedule.weekends" )}</Typography>
            <Typography variant="bigBody" weight="medium" className={styles.daysOff}>{t( "garden_schedule.day_off" )}</Typography>
          </div>
         
          <div className={styles.btn}>
            <Button href={socialData?.kindergarten_detail} target={"_blank"}>{t( "garden_schedule.button_text" )}</Button>
          </div>
          
          
        </div>
      </div>
    </div>
  );
};

