import { paths } from "@Utils/Constans/constants";

export const footerNavLinks = [
  {
    title: "footer.title",
    links: [
      { title: "footer.areas",href: paths.direction },
      { title: "footer.methods",href: paths.techniques },
      { title: "footer.programs",href: paths.programs },
      { title: "footer.specialist",href: paths.schedule },
      { title: "footer.training",href: paths.catalog },
      { title: "footer.kinderGarden",href: "#garden" }
    ]
  },{
    title: "footer.about",
    links: [
      { title: "footer.news",href: paths.ourblog },
      { title: "footer.aboutUs", href: paths.home }
    ]
  },{
    title: "footer.program",
    links: [
      { title: "footer.schedule", href: paths.schedule },
      { title: "footer.prices", href: paths.costOfClasses }
    ]
  },
];