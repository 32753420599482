import { create } from "zustand";
import { Api } from "@Utils/API/Api";

export const useLocationStore = create( ( set ) => ( {
  locations: [],
  isLoading: false,
  error: null,

  fetchLocations: async() => {
    set( { isLoading: true, error: null } );
    try {
      const response = await Api.get( "/location" );
      set( { locations: response.data, isLoading: false } );
    } catch ( error ) {
      set( { error: error.message, isLoading: false } );
    }
  },
} ) );
