import { useEffect, useState, useRef } from "react";
import { useClickOutSide } from "@Utils/hooks/useClickOutSide";
import { storeCatalog } from "../../store/StoreCatalog";
import { Typography } from "@Typography";
import { useTranslation } from "react-i18next";
import ScrollToTop from "@Utils/helpers/ScrollToTop";

import { ProductCard } from "../ProductCard/ProductCard";
import { ProductImageModal } from "../ProductImageModal/ProductImageModal";
import ProductCategory from "@Modules/CatalogProductsModule/components/ProductCategory/ProductCategory";
import Pagination from "@Components/Pagination/Pagination";
import Loading from "@Ui/Loading/Loading";
import styles from "./CatalogProducts.module.scss";
import Chevron from "@Assets/Icons/Chevron.svg";
import DeleteChangedCategory from "@Assets/Icons/x.svg";

export const CatalogProducts = () => {

  const { t } = useTranslation( "catalogpage" );

  const {
    products, 
    sortData, 
    fetchDataSort,
    fetchData, 
    loading, 
    currentPage
  } = storeCatalog();
  
  const [ selectCateg, setSelectCateg ] = useState( [] );
  const [ isOpen, setIsOpen ] = useState( false );
  const [ modalIsOpen, setModalIsopen ] = useState( false );
  const [ modalImgId, setModalImgId ] = useState( "" );

  const handleOpen = () => {
    setIsOpen( !isOpen );
  };

  const handleCategoryChange = ( id, category, isChecked ) => {
    setSelectCateg( ( prevCategories ) => {
      const updatedCategories = isChecked
        ? [ ...prevCategories, { id, category } ]
        : prevCategories.filter( ( cat ) => cat.id !== id );

      fetchDataSort( updatedCategories );
      return updatedCategories;
    } );
  };

  const delChanged = ( id ) => {
    setSelectCateg( ( prevCategories ) => {
      const updatedCategories = prevCategories
        .filter( ( cat ) => cat.id !== id );
      fetchDataSort( updatedCategories );
      return updatedCategories;
    } );
  };

  const handleModalFromCard = ( res ) => {
    setModalIsopen( res );
  };

  const handleModalImageFromCard = ( id ) => {
    setModalImgId( id );
  };

  const handleCloseModalFromImageModal = ( arg ) => {
    setModalIsopen( arg );
  };

  const modalRef = useRef( null );
  useClickOutSide( modalRef, () => setModalIsopen( false ) );


  useEffect( () => {
    fetchData( currentPage );
    fetchDataSort( selectCateg );
  }, [ fetchData, currentPage, fetchDataSort, selectCateg ] );

  useEffect( () => {
    if ( modalIsOpen ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [ modalIsOpen ] );

  if ( loading ) {
    return (
      <Loading />
    );
  }

  return (
    <section className="Container">
      <ScrollToTop/>
      <div className={styles.content}>
        <Typography variant="h1" color="DarkBlue" weight="medium">{t( "title" )}</Typography>
        <div className={styles.categ}>
          <div className={styles.categ_title} onClick={handleOpen}>
            <Typography variant="bigBody" weight="medium">
              {t( "category" )}
              <span>
                <img src={Chevron} alt="Chevron"
                  className={isOpen
                    ? styles.rotate
                    : styles.reverseRotate}
                />
              </span>
            </Typography>
          </div>
          <div className={styles.changed_categ}>
            {selectCateg.map( ( elem, i ) => 
              <span
                className={styles.cat}
                onClick={() => delChanged( elem.id )}
                key={i}
              >
                <Typography variant="h6">{elem.category}</Typography>
                <img src={DeleteChangedCategory} alt="DeleteChangedCategory" />
              </span>
            )}
          </div>
        </div>
        <div className={styles.categ_cards}>
          {isOpen && 
          <ProductCategory 
            selectedCategories={selectCateg} 
            onCategoryChange={handleCategoryChange} 
            handleOpen={handleOpen}
          />}
          <div className={styles.cards_pagin}>
            <div className={styles.cards}>
              <ProductCard
                products={products}
                modalFromCard={handleModalFromCard}
                productId={handleModalImageFromCard}
              />
            </div>
            <div className={styles.pagin}>
              {( products.next === null && products.previous === null ) || sortData.length > 0
                ? ""
                : <Pagination
                  count={products}
                  fetchData={fetchData}
                  currentPage={currentPage}
                  itemCount={12}
                />}
            </div>
          </div>
        </div>
      </div>
      <>
        {modalIsOpen && 
        <ProductImageModal 
          products={products} 
          modalImgId={modalImgId} 
          closeModal={handleCloseModalFromImageModal} 
        />}
      </>
    </section>
  );
};