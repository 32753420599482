import { createBrowserRouter } from "react-router-dom";
import {
  HomePage,
  Direction,
  CostOfClasses,
  Catalog,
  OurBlog,
  Programs,
  SchedulePage,
  Techniques,
  NotFoundPage,
} from "../Pages";
import { Layout } from "../Layout/Layout";
import { paths } from "@Utils/Constans/constants";

export const Router = createBrowserRouter( [
  {
    path: paths.home,
    element: <Layout />,
    children: [
      { 
        index: true,
        element: <HomePage /> 
      },
      {
        path: paths.direction,
        element: <Direction />, 
      },
      { 
        path: paths.costOfClasses,
        element: <CostOfClasses />,
      },
      { 
        path: paths.catalog,
        element: <Catalog />
      },
      { 
        path: paths.ourblog,
        element: <OurBlog />
      },
      { 
        path: paths.programs,
        element: <Programs />
      },
      { 
        path: paths.schedule,
        element: <SchedulePage />
      },
      { 
        path: paths.techniques,
        element: <Techniques />
      },
      { 
        path: paths.contacts,
        element: null
      },
    ]
  },
  {
    path: "*",
    element: <NotFoundPage />
  }
] );
