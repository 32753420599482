import { useState, useEffect } from "react";
import toUp from "../../Assets/Icons/socials/toUp.svg";
import styles from "./ScrollToTopButton.module.scss";

const ScrollToTopButton = () => {
  const [ isVisible, setIsVisible ] = useState( false );

  const toggleVisibility = () => {
    if ( window.pageYOffset > 300 ) {
      setIsVisible( true );
    } else {
      setIsVisible( false );
    }
  };

  const scrollToTop = () => {
    window.scrollTo( {
      top: 0,
      behavior: "smooth"
    } );
  };

  useEffect( () => {
    window.addEventListener( "scroll", toggleVisibility );
    return () => {
      window.removeEventListener( "scroll", toggleVisibility );
    };
  }, [] );

  return (
    <div className={styles.scrollToTop}>
      {isVisible && 
        <a onClick={scrollToTop}>
          <img src={toUp} alt="Scroll to top" />
        </a>
      }
    </div>
  );
};

export default ScrollToTopButton;
