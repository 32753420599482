import { useEffect, useState } from "react";
import { Typography } from "@Typography";
import { storeCatalog } from "@Modules/CatalogProductsModule/store/StoreCatalog";
import { useTranslation } from "react-i18next";
import Button from "@Ui/Buttons/Button";
import styles from "./ProductCard.module.scss";
import AltPhoto from "@Assets/Images/alternativePhoto.jpg";

export const ProductCard = ( { products, modalFromCard, productId } ) => {

  const { t } = useTranslation( "catalogpage" );

  const sortData = storeCatalog( ( state ) => state.sortData );
  
  const [ productsArr, setProductArr ] = useState( [] );
  const [ isEmpty, setIsEmpty ] = useState( false );

  const handleModalOpen = ( Id ) => {
    modalFromCard( true );
    productId( Id );
  };

  const replaceImage = ( error ) => {
    error.target.src = AltPhoto;
  };

  useEffect( () => {
    if ( products && products.results ) {
      if ( sortData.length > 0 ) {
        setProductArr( sortData );
      } else {
        setProductArr( products.results );
      }
    }
  }, [ products, sortData ] );

  useEffect( () => {
    if ( productsArr.length === 0 ) {
      const timer = setTimeout( () => (
        setIsEmpty( true )
      ), 2000 );

      return () => clearTimeout( timer );
    } else {
      setIsEmpty( false );
    }
  }, [ productsArr ] );

  if ( isEmpty ) {
    return (
      <Typography variant="h1" color="DarkBlue" >Здесь пусто</Typography>
    );
  }

  return (
    productsArr.map( ( elem, i ) => (
      <div className={styles.сard} key={i}>
        <div className={styles.photo} onClick={() => handleModalOpen( elem.id )}>
          {( () => {
            const mainImage = elem.images.find( ( img ) => img.is_main );
            const imageToShow = mainImage || elem.images[0];
            console.log( "imageToShow", mainImage );
            return <img src={imageToShow ? imageToShow.image : AltPhoto} alt={elem.title} onError={replaceImage}/>;
            
          } )()}
        </div>
        <div className={styles.text}>
          <div className={styles.title}>
            <Typography
              variant="bodySmall"
              weight="medium"
              className={styles.title_name}
            >
              {elem.title}
            </Typography>
            <Typography
              variant="bodySmall"
              weight="medium"
              className={styles.price}
            >
              {elem.price}<span>с</span>
            </Typography>
          </div>
          <div className={styles.description}>
            <Typography variant="bodyExtraSmall">{elem.description}</Typography>
          </div>
        </div>
        <Button
          size={"full"}
          href={elem.order_link}
          target="_blank"
          className={styles.order_btn}
        >
          {t( "button_order" )}
        </Button>
      </div>
    ) )
  );
};