import { useRef, useState, useEffect } from "react";

export const useMouseScroll = () => {
  const scrollRef = useRef( null );
  const [ isDragging, setIsDragging ] = useState( false );
  const [ startX, setStartX ] = useState( 0 );
  const [ scrollLeft, setScrollLeft ] = useState( 0 );

  const handleMouseDown = ( e ) => {
    setIsDragging( true );
    setStartX( e.pageX - scrollRef.current.offsetLeft );
    setScrollLeft( scrollRef.current.scrollLeft );
  };

  const handleMouseLeave = () => {
    setIsDragging( false );
  };

  const handleMouseUp = () => {
    setIsDragging( false );
  };

  const handleMouseMove = ( e ) => {
    if ( !isDragging ) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = x - startX;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  useEffect( () => {
    const currentRef = scrollRef.current;

    if ( currentRef ) {
      currentRef.addEventListener( "mousedown", handleMouseDown );
      currentRef.addEventListener( "mouseleave", handleMouseLeave );
      currentRef.addEventListener( "mouseup", handleMouseUp );
      currentRef.addEventListener( "mousemove", handleMouseMove );

      return () => {
        currentRef.removeEventListener( "mousedown", handleMouseDown );
        currentRef.removeEventListener( "mouseleave", handleMouseLeave );
        currentRef.removeEventListener( "mouseup", handleMouseUp );
        currentRef.removeEventListener( "mousemove", handleMouseMove );
      };
    }
  } );

  return scrollRef;
};
