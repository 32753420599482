import { motion } from "framer-motion";

export const AnimationBox = ( props ) => {

  const  { children, delay, animationType = "toUp", className } = props;

  const variants = {
    toUp: {
      initial: { opacity: 0, y: 100 }, 
      whileInView: { opacity: 1, y: 0 },
    },
    toRight: {
      initial: { opacity: 0, x: 100 }, 
      whileInView: { opacity: 1, x: 0 },
    },
    toLeft: {
      initial: { opacity: 0, y: 100 }, 
      whileInView: { opacity: 1, y: 0 },
    },
    scale: {
      initial: { opacity: 0, scale: 0.5 },
      whileInView: { opacity: 1, scale: 1 },
    },
    fadeIn: {
      initial: { opacity: 0 },
      whileInView: { opacity: 1 },
      transition: { duration: 0.5 },
    }
  };

  return (
    <>
      <motion.span
        initial={variants[animationType].initial}
        whileInView={variants[animationType].whileInView}
        transition={{ duration: 0.5, delay }}
        viewport={{ once: true, amount: 0.1 }}
        className={className}
      >
        {children}
      </motion.span>
    </>
  );
};

