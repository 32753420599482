import { useEffect } from "react";
import DirectionModal from "@Modules/DirectionModal/DirectionModal";
import { Typography } from "@Ui/Typography/Typography";
import HTMLReactParser from "html-react-parser";
import styles from "./Direction.module.scss";
import Button from "@Ui/Buttons/Button";
import useDirectionsStore from "../store/storeDirection";
import Loading from "@Ui/Loading/Loading";
import { useTranslation } from "react-i18next";

export const DirectionPage = () => {
  const { t } = useTranslation( "direction" );
  const directions = useDirectionsStore( ( state ) => state.directions );
  const fetchDirections = useDirectionsStore( ( state ) => state.fetchDirections );
  const isLoading = useDirectionsStore( ( state ) => state.isLoading );
  const error = useDirectionsStore( ( state ) => state.error );
  const isModalOpen = useDirectionsStore( ( state ) => state.isModalOpen );
  const modalContent = useDirectionsStore( ( state ) => state.modalContent );
  const openModal = useDirectionsStore( ( state ) => state.openModal );
  const closeModal = useDirectionsStore( ( state ) => state.closeModal );

  useEffect( () => {
    fetchDirections();
  }, [ fetchDirections ] );

  const getBackgroundColor = ( index ) => {
    const colors = [ "#A0DDFF", "#FFFE85", "#FEDFD4" ];
    return [ 0, 4, 6, 10, 12 ].includes( index )
      ? colors[0]
      : [ 1, 5, 7, 11, 13 ].includes( index )
        ? colors[1]
        : colors[2];
  };

  if ( isLoading ) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <Typography variant="h2" className={styles.heading}>
        {t( "direction.title" )}
      </Typography>
      <div className={styles.grid}>
        {isLoading ? (
          <Loading />
        ) : error ? (
          <div>{error}</div>
        ) : (
          directions.map( ( direction, index ) => (
            <div
              key={index}
              className={styles.card}
              style={{ backgroundColor: getBackgroundColor( index ) }}
            >
              <Typography variant="h3" className={styles.cardTitle}>
                {direction.title}
              </Typography>
              <Typography variant="body" className={styles.cardDescription}>
                {HTMLReactParser( direction.description )}
              </Typography>
              <Button color={"white"} onClick={() => openModal( direction )}>
                {t( "direction.readmore" )}
              </Button>

            </div>
          ) )
        )}
      </div>
      {isModalOpen && (
        <div className={styles.modalOverlay} onClick={closeModal}>
          <div className={styles.modalContent} onClick={( e ) => e.stopPropagation()}>
            <DirectionModal
              title={modalContent.title}
              description={modalContent.description}
              onClose={closeModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};
