import { useState, useEffect } from "react";
import { useMethodologiesStore } from "../store/storeTechniques";
import toDown from "../../../Assets/Icons/socials/todown.svg";
import styles from "./Techniques.module.scss";
import { Typography } from "@Ui/Typography/Typography";
import useMediaQuery from "@Utils/hooks/useMediaQuery";
import Loading from "@Ui/Loading/Loading";
import { useTranslation } from "react-i18next";

export const TechniquesModule = () => {
  const { t } = useTranslation( "techniques" );
  const { methodologies, fetchMethodologies, isLoading } = useMethodologiesStore();
  const [ expandedStates, setExpandedStates ] = useState( {} );
  const [ visibleItems, setVisibleItems ] = useState( 3 );
  const isMobile = useMediaQuery( "(max-width: 768px)" );
  const isTablet = useMediaQuery( "(max-width: 1250px)" );

  const maxLengthDescription = isMobile ? 160 : isTablet ? 260 : 650;
  const readMoreText = t( "techniques.readMore" );
  const hideText = t( "techniques.hide" );

  useEffect( () => {
    fetchMethodologies();
  }, [ fetchMethodologies ] );

  useEffect( () => {
    setExpandedStates(
      methodologies.reduce( ( acc, item ) => ( { ...acc, [item.id]: false } ), {} )
    );
  }, [ methodologies ] );

  const toggleReadMore = ( id ) => {
    setExpandedStates( ( prev ) => ( { ...prev, [id]: !prev[id] } ) );
  };

  const showMoreItems = () => {
    setVisibleItems( ( prev ) => Math.min( prev + 3, methodologies.length ) );
  };

  if ( isLoading ) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <Typography variant="h1" color="blue" weight="medium">
            {t( "techniques.title" )}
          </Typography>
        </div>
        {methodologies.slice( 0, visibleItems ).map( ( item ) => {
          const isExpanded = expandedStates[item.id];
          const isShouldShowReadMore = item.description.length > maxLengthDescription;
          const displayedDescription = isExpanded || !isShouldShowReadMore
            ? item.description
            : `${item.description.substring( 0, maxLengthDescription )}`;

          return (
            <div className={`${styles.article} ${isExpanded ? styles.expanded : ""}`} key={item.id}>
              <div className={styles.img_}>
                <img src={item.photo} alt={item.title} />
              </div>
              <div className={styles.articleText}>
                <div className={`${styles.text2} ${isExpanded ? styles.expanded : ""}`}>
                  <Typography color="blue" weight="medium">
                    {item.title}
                  </Typography>
                  {isShouldShowReadMore && (
                    <div
                      className={`${styles.moreDetails} ${isExpanded ? styles.expanded : ""}`}
                      onClick={() => toggleReadMore( item.id )}
                    >
                      <img src={toDown} alt="Toggle Details" />
                    </div>
                  )}
                </div>
                <div className={styles.text3}>
                  <Typography variant="bodySmall">
                    {displayedDescription}
                    {isShouldShowReadMore && (
                      <span
                        className={styles.readMore}
                        onClick={() => toggleReadMore( item.id )}
                      >
                        {!isExpanded ? readMoreText : hideText}
                      </span>
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          );
        } )}
      </div>
      {visibleItems < methodologies.length && (
        <button onClick={showMoreItems} className={styles.btn}>
          {t( "techniques.btn" )}
        </button>
      )}
    </>
  );
};
