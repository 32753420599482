import styles from "./QuestionsItems.module.scss";
import classNames from "classnames";
import { QuestionsItem } from "../QuestionsItem/QuestionsItem";
import { useQuestionStore } from "../store/useQuestionStore";
import { AnimationBox } from "@Ui/AnimationBox/AnimationBox";
import { Typography } from "@Ui/Typography/Typography";
import { useEffect, useState } from "react";
import closeMode from "@Assets/Icons/closeMode.svg";
import Loading from "@Ui/Loading/Loading";
import { useTranslation } from "react-i18next";


export const QuestionsItems = () => {
  const { data, getData, loading } = useQuestionStore();
  const [ activeIndex, setActiveIndex ] = useState( null );
  const { t } = useTranslation( "homepage" );

  const handleClick = ( index ) => {
    setActiveIndex( activeIndex === index ? null : index );
  };

  useEffect( () => {
    getData();
  }, [ getData ] );

  return (
    <section className={classNames( styles.questionsBlock, "Container" )}>
      <Typography
        variant="h1"
        weight="medium"
        color="DarkBlue"
        className={styles.questionsBlockTitle}
      >
        {t( "questions" )}
      </Typography>
      {loading ? 
        ( <Loading/> ) 
        : (
          <div >
            {data && data.map( ( item, index ) => (
              <AnimationBox 
                key={index} 
                animationType="toUp" 
                delay={index * 0.1}
              >
                <QuestionsItem
                  question={item.question}
                  answer={item.answer}
                  icon={closeMode}
                  isOpen={activeIndex === index}
                  onClick={() => handleClick( index )}
                />
              </AnimationBox>
            ) )}
          </div>
        )
      }
    </section>
  );
};

