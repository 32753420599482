import { useState, useEffect } from "react";
import useMediaQuery from "@Utils/hooks/useMediaQuery";
import styles from "./ProgramCard.module.scss";
import toDown from "@Assets/Icons/socials/todown.svg";
import { Typography } from "@Ui/Typography/Typography";
import { useProgramsStore } from "../../store/store.Programs";
import Button from "@Ui/Buttons/Button";
import { useSocialStore } from "@Modules/HomePageModule/store/useSocialStore";
import Loading from "@Ui/Loading/Loading";
import DefaultImg from "../../../../Assets/Images/alternativePhoto.jpg";
import { useTranslation } from "react-i18next";


export const ProgramsCard = () => {
  const { t } = useTranslation( "programspage" );
  const { programs, fetchprograms, isLoading } = useProgramsStore();
  const [ expandedStates, setExpandedStates ] = useState( {} );
  const [ visibleItems, setVisibleItems ] = useState( 3 );
  const [ maxLengthDescription, setMaxLengthDescription ] = useState( 745 );
  const [ readMoreText, setReadMoreText ] = useState( "Читать далее..." );
  const [ buttonSize, setButtonSize ] = useState( "medium" );

  const { data } = useSocialStore();
  const whatsappData = data.find( ( item ) => item.urls.includes( "wa.me" ) );

  const isMobile = useMediaQuery( "(max-width: 768px)" );
  const isTablet = useMediaQuery( "(max-width: 1250px)" );
  const isDesktop = useMediaQuery( "(min-width: 1251px)" );

  useEffect( () => {
    fetchprograms();
  }, [ fetchprograms ] );

  useEffect( () => {
    setExpandedStates(
      programs.reduce( ( acc, item ) => ( { ...acc, [item.id]: false } ), {} )
    );
  }, [ programs ] );

  useEffect( () => {
    if ( isMobile ) {
      setMaxLengthDescription( 150 );
      setReadMoreText( <p>{t( "text_button2" )}</p> );
      setButtonSize( "full" );
    } else if ( isTablet ) {
      setMaxLengthDescription( 250 );
      setReadMoreText( <p>{t( "text_button2" )}</p> );
      setButtonSize( "medium" );
    } else if ( isDesktop ) {
      setMaxLengthDescription( 545 );
      setReadMoreText( <p>{t( "text_button2" )}</p> );
      setButtonSize( "medium" );
    }
  }, [ isMobile, isTablet, isDesktop, t ] );

  const toggleReadMore = ( id ) => {
    setExpandedStates( ( prev ) => ( { ...prev, [id]: !prev[id] } ) );
  };

  const showMoreItems = () => {
    setVisibleItems( ( prevVisibleItems ) => {
      const newVisibleItems = prevVisibleItems + 3;
      return newVisibleItems >= programs.length ? programs.length : newVisibleItems;
    } );
  };

  if ( isLoading ) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className={styles.title}>
        <Typography variant="h1" color="DarkBlue" weight="medium">
          {t( "title" )}
        </Typography>
      </div>

      {programs.slice( 0, visibleItems ).map( ( item ) => {
        const isExpanded = expandedStates[item.id];
        const isShouldShowReadMore =
          item.description.length > maxLengthDescription;
        const displayedDescription =
          isExpanded || !isShouldShowReadMore
            ? item.description
            : `${item.description.substring( 0, maxLengthDescription )}`;

        return (
          <div
            className={`${styles.article} ${isExpanded ? styles.expanded : ""}`}
            key={item.id}
          >
            <div className={styles.img_}>
              <img
                src={item.photo}
                alt="Foto"
                onError={( e ) => {
                  e.target.src = DefaultImg;
                }}
              />
            </div>
            <div className={styles.articleText}>
              <div className={styles.text2}>
                <Typography variant="bigBody" color="blue" weight="medium">
                  {item.title}
                </Typography>
                {isShouldShowReadMore && (
                  <div
                    className={`${styles.moreDetails} ${
                      isExpanded ? styles.expanded : ""
                    }`}
                    onClick={() => toggleReadMore( item.id )}
                  >
                    <img src={toDown} alt="down" />
                  </div>
                )}
              </div>
              <div className={styles.text3}>
                <Typography variant="bodySmall">
                  {displayedDescription}
                  {isShouldShowReadMore && (
                    <>
                      {!isExpanded ? (
                        <span
                          className={`${styles.readMore} ${
                            isExpanded ? styles.expanded : ""
                          }`}
                          onClick={() => toggleReadMore( item.id )}
                        >
                          <Typography variant="bodySmall" weight="semibold">
                            {readMoreText}
                          </Typography>
                        </span>
                      ) : (
                        <span
                          className={`${styles.readMore} ${
                            isExpanded ? styles.expanded : ""
                          }`}
                          onClick={() => toggleReadMore( item.id )}
                        >
                          <Typography variant="bodySmall" weight="semibold">
                            {t( "text_button3" )}
                          </Typography>
                        </span>
                      )}
                    </>
                  )}
                </Typography>

                {whatsappData && (
                  <div className={styles.button}>
                    <Button size={buttonSize} href={whatsappData.urls} target="_blank">
                      {t( "text_button" )}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      } )}
      <div className={styles.PlusBtn}>
        {visibleItems < programs.length && (
          <button onClick={showMoreItems}>{t( "text_button4" )}</button>
        )}
      </div>
    </div>
  );
};
