import { useEffect, useRef } from "react";
import styles from "./Maps.module.scss";
import { Typography } from "@Ui/Typography/Typography";
import VECTOR from "../../../Assets/Images/Vector.png";
import { useLocationStore } from "../store/storeMaps";
import DG from "2gis-maps";
import Loading from "@Ui/Loading/Loading";
import { useTranslation } from "react-i18next";

export const Maps = () => {
  const { t } = useTranslation( "maps" );
  const { locations, fetchLocations, isLoading, error } = useLocationStore();
  const mapRef = useRef( null );
  
  useEffect( () => {
    fetchLocations();
  }, [ fetchLocations ] );
  
  const extractCoordinatesFromUrl = ( url ) => {
    const regex = /m=(\d+\.\d+)%2C(\d+\.\d+)/;
    const match = url.match( regex );
    if ( match ) {
      return [ parseFloat( match[2] ), parseFloat( match[1] ) ];
    }
    return null;
  };
  
  useEffect( () => {
    if ( !mapRef.current ) return;
  
    const map = DG.map( mapRef.current, {
      center: [ 42.8446, 74.5922 ], 
      zoom: 12,
    } );
  
    locations.forEach( ( location ) => {
      const coords = extractCoordinatesFromUrl( location.url_logocenter );
      if ( coords ) {
        DG.marker( coords )
          .addTo( map )
          .bindPopup( `<b>${location.name}</b><br>${location.address}` );
      }
    } );
      
    return () => {
      map.remove();
    };
  }, [ locations ] );
  
  if ( isLoading ) {
    return <Loading/>;
  }
  
  if ( error ) {
    return <div>Error: {error}</div>; 
  }
  
  return (
    <div id="maps" className="container" >
      <div className={styles.mapsContainer}>
        <div className={styles.mapsBlock1}>
          <div className={styles.mapsTitle}>
            <Typography variant="h3" weight="medium">
              {t( "maps.title" )}
            </Typography>
          </div>
          <div className={styles.addresses}>
            <div className={styles.addressesHeader}>
              <img src={VECTOR} alt="Vector icon" />
              <Typography variant="h2" color="primary" weight="medium">
                {t( "maps.adress" )}
              </Typography>
            </div>
            <ul className={styles.addressList}>
              {locations.map( ( location, index ) => (
                <li key={location.id}>
                  {index === 3 ? (
                    <a href={location.url_logocenter} target="_blank" rel="noopener noreferrer">
                      <Typography variant="h3" color="primary">{location.name}</Typography>
                      <Typography variant="body" color="primary">{location.address}</Typography>
                    </a>
                  ) : (
                    <a
                      href={`https://2gis.kg/bishkek/geo/${location.url_logocenter},${location.url_logocenter}?m=${location.url_logocenter},${location.url_logocenter}/16`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography variant="h3" color="primary">{location.name}</Typography>
                      <Typography variant="body" color="primary">{location.address}</Typography>
                    </a>
                  )}
                  <Typography variant="body" color="primary" className={styles.phone}>
                    <a href={`tel:${location.tel}`}>{t( "maps.mobile" )}: {location.tel || "Номер телефона не указан"}</a>
                  </Typography>
                </li>
              ) )}
            </ul>
          </div>
        </div>
  
        <div className={styles.mapsBlock2}>
          <div ref={mapRef} className={styles.mapContainer}>
              
          </div>
        </div>
      </div>
    </div>
  );};