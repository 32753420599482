import styles from "./BlogCard.module.scss";
import { Typography } from "@Typography";
import { YouTubePlayer } from "@Components/YouTubePlayer/YouTubePlayer";
import useMediaQuery from "@Utils/hooks/useMediaQuery";
import AltImage from "@Assets/Images/alternativePhoto.jpg";

export const BlogCard = ( { title, photo, description, created_at, video_url } ) => {
  const isTablet = useMediaQuery( "(max-width: 1250px)" );

  const handleImageError = ( event ) => {
    event.target.src = AltImage;
  };

  return (
    <div>
      <div className={styles.card}>
        <div>
          {photo ? (
            <div className={styles.photo}>
              <img
                src={photo || AltImage}
                alt="BlogPhoto" 
                onError={handleImageError}
              />
            </div>
          ) : (
            <div className={styles.blogPlayer}>
              <YouTubePlayer
                borderRadius="10px" 
                videoUrl={video_url || AltImage} 
                onError={handleImageError}
              />
            </div>
          )}
        </div>
        <div className={styles.card_text}>
          <Typography 
            variant="bodyExtraSmall" 
            weight="medium"
          >
            {created_at}
          </Typography>
          <Typography 
            variant="body"
            weight="medium"
            color="black" 
          >{title}</Typography>
          <Typography
            variant="bodyExtraSmall"
            weight="regular"
            truncate={isTablet ? 80 : 120}
          >
            {description}
          </Typography>
        </div>
      </div>
    </div>
  );
};

