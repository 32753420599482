import styles from "./OurTeam.module.scss";
import Button from "@Ui/Buttons/Button";
import { TeamCard } from "@Ui/TeamCard/TeamCard";
import { useOurTeamStore }from "../store/useOurTeamStore";
import { Typography } from "@Typography";
import { useEffect } from "react";
import { paths } from "@Utils/Constans/constants";
import { Link } from "react-router-dom";
import { useMouseScroll } from "@Utils/hooks/useMouseScroll";
import Loading from "@Ui/Loading/Loading";
import { useTranslation } from "react-i18next";


export const OurTeam = () => {
  const { data, loading, getData } = useOurTeamStore();
  const { t } = useTranslation( "homepage" );
  
  const scrollRef = useMouseScroll();

  useEffect( () => {
    getData();
  }, [ getData ] );
  
  if ( loading ) return <Loading/>;

  return (
    <div className="Container">
      <div className={styles.content}>
        <Typography 
          variant="h1"
          weight="medium"
          color="DarkBlue"
          className={styles.title}
        >
          {t( "ourTeam.title" )}
        </Typography>
        <div className={styles.scroll}
          ref={scrollRef}
        >
          { data && data.map( ( item, index ) => <TeamCard key={index} {...item} /> ) }
        </div>
        <Button>
          <Link to={paths.schedule}>
            {t( "ourTeam.btn" )}
          </Link>
        </Button>
      </div>
    </div>
  );
};
