import { useRef, useState, useEffect } from "react";
import { Typography } from "@Typography";
import { useClickOutSide } from "@Utils/hooks/useClickOutSide";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import styles from "./LanguageSelector.module.scss";
import kgFlag from "@Assets/Icons/Kyrgyzstan.svg";
import ruFlag from "@Assets/Icons/Russian.svg";
import arrow from "@Assets/Icons/Chevron.svg";

const LanguageSelect = () => {

  const { i18n } = useTranslation();

  const languages = [
    { code: "ru", name: "Русский", flag: ruFlag },
    { code: "ky", name: "Кыргызча", flag: kgFlag }
  ];

  const [ selectedLanguage, setSelectedLanguage ] = useState( JSON.parse( localStorage.getItem( "language" ) ) || languages[0] );
  const [ isOpen, setIsOpen ] = useState( false );
  const dropdownRef = useRef( null );

  const handleLanguageChange = ( language ) => {
    setSelectedLanguage( language );
    setIsOpen( false );
    localStorage.setItem( "language", JSON.stringify( language ) );
    window.location.reload();
    i18n.changeLanguage( language.code );
  };

  const toggleDropdown = () => {
    setIsOpen( !isOpen );
  };

  const slideVerticalAnimation = {
    open: {
      rotateX: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
      display: "block"
    },
    close: {
      rotateX: 0,
      y: -20,
      opacity: 0,
      transition: {
        duration: 0.5
      },
      transitionEnd: {
        display: "none"
      }
    }
  };
  
  useClickOutSide( dropdownRef, () => {setIsOpen( false );} );

  useEffect( () => {
    const language = JSON.parse( localStorage.getItem( "language" ) );
    if ( language && language.code ) {
      i18n.changeLanguage( language.code );
    }
  }, [ i18n ] );

  return (
    <div className={styles.lang_change}>
      <div className={styles.dropdown} ref={dropdownRef}>
        <div className={styles.dropdown_button} onClick={() => toggleDropdown()}>
          <div className={styles.flag}>
            <img src={selectedLanguage.flag} alt="flag" />
          </div>
          <Typography variant="h6">{selectedLanguage.name}</Typography>
          <span>
            <img src={arrow} alt="Стрелка" 
              className={isOpen ? styles.rotate : styles.reverseRotate}
            />
          </span>
        </div>
        {isOpen && (
          <motion.div
            className={styles.dropdown_content}
            initial="close"
            animate={isOpen ? "open" : "close"}
            variants={slideVerticalAnimation}
          >
            { languages
              .filter( ( language ) => language.name !== selectedLanguage.name )
              .map( ( language ) => (
                <div
                  key={language.code}
                  onClick={() => handleLanguageChange( language )}
                  className={styles.dropdown_item}
                >
                  <img src={language.flag} alt="Флаг" />
                  <Typography variant="h6">{language.name}</Typography>
                </div>
              ) ) }
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default LanguageSelect;