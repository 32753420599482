// cтраница "главная"

import { 
  StartBlock,
  HelpForKids,
  Center, 
  ToKid,
  SliderGallery,
  Garden, 
  WeNumbersHome,
  OurMission,
  OurTeam,
  Methodology,
  QuestionsItems,
  OurBlog } from "@Modules/HomePageModule";
import { ReviewsSwiper } from "@Components/ReviewsSwiper/ReviewsSwiper";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


export const HomePage = () => {
  const { t } = useTranslation( "navigation" );
  return  (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t( "home" )}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <StartBlock/>
      <HelpForKids/>
      <Center/>
      <ToKid/>
      <SliderGallery/>
      <Garden/>
      <WeNumbersHome/>
      <OurMission/>
      <OurTeam/>
      <Methodology/>
      <ReviewsSwiper/>
      <QuestionsItems/>
      <OurBlog/>
    </>
  );
};
