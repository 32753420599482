import { useRef, useState, useEffect } from "react";
import { useClickOutSide } from "@Utils/hooks/useClickOutSide";
import { SwiperSlide } from "swiper/react";
import { Typography } from "@Ui/Typography/Typography";
import { Swiper } from "swiper/react";
import { Navigation } from "swiper/modules";
import CloseModal from "@Assets/Icons/closeMod.svg";
import styles from "./ProductImageModal.module.scss";
import AltPhoto from "@Assets/Images/alternativePhoto.jpg";
import "./CatalogSwiper.scss";

export const ProductImageModal = ( { closeModal, products, modalImgId } ) => {

  const [ productsArr, setProductArr ] = useState( [] );

  const modalRef = useRef( null );
  useClickOutSide( modalRef, () => handleCloseModal() );

  const handleCloseModal = () => {
    closeModal( false );
  };

  const replaceImage = ( error ) => {
    error.target.src = AltPhoto;
  };

  useEffect( () => {
    if ( products && products.results ) {
      setProductArr( products.results );
    }
  }, [ products ] );

  return (
    <div className={styles.modal}>
      <div className={styles.modal_content} ref={modalRef}>
        <span className={styles.close} onClick={handleCloseModal}>
          <img src={CloseModal} alt="Close" />
        </span>
        <div className={styles.card}>
          <Swiper
            navigation={true}
            modules={[ Navigation ]}
            className="swiper_catalog_madal"
          >
            {productsArr.map( ( elem ) =>
              elem.id === modalImgId &&
              elem.images.map( ( img, j ) => (
                <SwiperSlide key={j} >
                  <img 
                    src={img ? img.image : AltPhoto} 
                    alt={elem.title} 
                    onError={replaceImage}
                  />
                </SwiperSlide>
              ) )
            )}
          </Swiper>
          {
            productsArr
              .filter( ( elem ) => elem.id === modalImgId )
              .map( ( elem, i ) => (
                <div className={styles.text} key={i}>
                  <div className={styles.title}>
                    <Typography variant="bodySmall" weight="medium">{elem.title}</Typography>
                    <Typography variant="bodySmall" weight="medium">{elem.price}</Typography>
                  </div>
                  <div className={styles.description}>
                    <Typography variant="bodyExtraSmall">{elem.description}</Typography>
                  </div>
                </div>
              ) )
          }
        </div>
      </div>
    </div>
  );
};