import { useState, useEffect } from "react";
import useMediaQuery from "@Utils/hooks/useMediaQuery";
import { Typography } from "@Ui/Typography/Typography";
import { YouTubePlayer } from "@Components/YouTubePlayer/YouTubePlayer";
import { useTranslation } from "react-i18next";
import styles from "./OurBlogCard.module.scss";
import More from "@Assets/Icons/more.svg";
import Loading from "@Ui/Loading/Loading";
import AltPhoto from "@Assets/Images/alternativePhoto.jpg";

export const OurBlogCard = ( { blog, loading } ) => {

  const { t } = useTranslation( "ourBlog" );

  const [ isExpanded, setIsEpanded ] = useState( false );
  const [ maxLengthDescription, setMaxLengthDescription ] = useState( 550 ); 

  const isMediumScreen = useMediaQuery( "(min-width: 769px) and (max-width: 1250px)" );
  const isSmallScreen = useMediaQuery( "(max-width: 768px)" );

  const toggleReadMore = () => {
    setIsEpanded( !isExpanded );
  };

  const replaceImage = ( error ) => {
    error.target.src = AltPhoto;
  };

  const isShowReadMore = blog.description.length > maxLengthDescription;
  const displayedDescription = isExpanded || !isShowReadMore
    ? blog.description
    : `${blog.description.substring( 0, maxLengthDescription )}...`;

  useEffect( () => {
    if ( isMediumScreen ) {
      setMaxLengthDescription( blog.title.length > 42 ? 360 : 420 );
    } else if ( isSmallScreen ) {
      setMaxLengthDescription( 100 );
    } else {
      setMaxLengthDescription( 550 );
    }
  }, [ isSmallScreen, isMediumScreen, blog.title.length ] );

  if ( loading ) {
    return <Loading />;
  }

  return (
    <div className={styles.card}>
      {isShowReadMore && (
        <div className={styles.moreIcon} onClick={toggleReadMore}>
          <img
            src={More}
            alt="More"
            style={
              isExpanded
                ? { transform: "rotate(180deg)", transition: "transform 0.3s" }
                : { transition: "transform 0.3s" }
            }
          />
        </div>
      )}
      <div className={styles.photo}>
        {blog.photo ? (
          <img src={blog.photo} alt={AltPhoto} onError={replaceImage} />
        ) : (
          <div className={styles.blogPlayer}>
            <YouTubePlayer videoUrl={blog.video_url} />
          </div>
        )}
      </div>
      <div className={styles.text_content}>
        <Typography variant="h4" weight="medium">
          {blog.title}
        </Typography>
        <Typography variant="bodyExtraSmall" weight="medium">
          {blog.created_at}
        </Typography>
        <div className={styles.text}>
          <Typography variant="bodySmall">
            {displayedDescription}
            {isShowReadMore && (
              <span className={styles.readMore} onClick={toggleReadMore}>
                {!isExpanded ? <span> {t( "button_read_more" )}...</span> : <span> {t( "button_close" )}</span>}
              </span>
            )}
          </Typography>
        </div>
      </div>
    </div>
  );
};
