import CloseModal from "@Assets/Icons/closeMod.svg";
import styles from "./ModalScheduleCard.module.scss";

export  const ModalScheduleCard = ( { isOpen, closeModal, children } ) => (
  <>
    {isOpen && (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <a className={styles.closeButton} onClick={closeModal}><img src={CloseModal} alt="close" /></a>
          {children}
        </div>
      </div>
    )}
  </>
);



