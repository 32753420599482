import styles from "./YouTubePlayer.module.scss";

export const YouTubePlayer = ( { videoUrl, borderRadius = "0px" } ) => {
  if ( !videoUrl ) {
    console.error( "videoUrl не определен" );
    return null;
  }

  let videoId = null;

  if ( videoUrl.includes( "youtube.com" ) ) {
    const urlParams = new URLSearchParams( new URL( videoUrl ).search );
    videoId = urlParams.get( "v" );
  } else if ( videoUrl.includes( "youtu.be" ) ) {
    videoId = videoUrl.split( "/" ).pop();
  }

  if ( !videoId ) {
    console.error( "Неверный YouTube URL" );
    return null;
  }

  return (
    <div
      className={styles.playerWrapper}
      style={{ borderRadius: borderRadius }}
    >
      <iframe
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

