import style from "./Numbers.module.scss";
import { Typography } from "@Typography";
import { useRef, useState, useEffect } from "react";
import { numbersList } from "./constants/constants";
import alternatePhoto from "@Assets/Images/alternativePhoto.jpg";
import { useTranslation } from "react-i18next";

export const WeNumbersHome = () => {
  const [ animatedValues, setAnimatedValues ] = useState( numbersList.map( () => 0 ) );
  const [ animationTriggered, setAnimationTriggered ] = useState( false );
  const counterRef = useRef( null );

  useEffect( () => {
    const handleScroll = () => {
      const position = counterRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if ( position.top < windowHeight && position.bottom >= 0 && !animationTriggered ) {
        setAnimationTriggered( true );
        numbersList.forEach( ( item, index ) => animateValue( index, item.target, item.duration ) );
      }
    };

    window.addEventListener( "scroll", handleScroll );

    return () => {
      window.removeEventListener( "scroll", handleScroll );
    };
  }, [ animationTriggered ] );
  const { t } = useTranslation( "homepage" );

  const animateValue = ( index, target, duration ) => {
    let start = null;

    const step = ( timestamp ) => {
      if ( !start ) start = timestamp;
      const progress = timestamp - start;

      const currentValue = Math.min( ( progress / duration ) * target, target );
      setAnimatedValues( ( prevValues ) => {
        const newValues = [ ...prevValues ];
        newValues[index] = Math.round( currentValue );
        return newValues;
      } );

      if ( progress < duration ) {
        requestAnimationFrame( step );
      }
    };

    requestAnimationFrame( step );
  };

  const handleImageError = ( event ) => {
    event.target.src = alternatePhoto;
  };

  return (
    <div className={style.container}>
      <Typography 
        variant="h1"
        weight="medium"
        color="DarkBlue"
        className={style.title}
      >
        {t( "weNumbersHome.title" )}
      </Typography>
      <div className={style.wrapper} ref={counterRef}>
        {numbersList.map( ( item, index ) => (
          <div className={style.blockNumbersWe} key={index}>
            <div className={style.blockNumber}>
              <div className={style.employeeBlock}>
                <img 
                  src={item.icon || alternatePhoto} 
                  alt={item.title}
                  onError={handleImageError}
                />
              </div>
              <Typography variant="h3" weight="semibold" className={animationTriggered && style.animated}>
                {animatedValues[index]}{item.suffix}
              </Typography>
            </div>
            <Typography variant="bodyExtraSmall" color="Grey" weight="medium">
              {t( item.title )}
            </Typography>
          </div>
        ) )}
      </div>
    </div>
  );
};

