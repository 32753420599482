import { Schedule } from "@Modules/SchedulePageModule";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const SchedulePage = () => {
  const { t } = useTranslation( "navigation" );
  return  (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t( "schedule" )}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Schedule/>
    </>
  );
};

