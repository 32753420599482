import styles from "./CostItem.module.scss";
import { AnimationBox }from "@Ui/AnimationBox/AnimationBox";
import { Typography } from "@Ui/Typography/Typography";

export const CostItem = ( props ) => {
  const { category, sessions, time } = props;
  return(
    <AnimationBox delay={time * 0.1} className={styles.costItem}>
      <div>
        <Typography
          variant="bigBody"
          color="black"
          weight="medium"
          className={styles.costItemTitle}
        >
          {category}
        </Typography>
        <ul>
          {sessions.map( ( session, index ) => (
            <li key={index} className={styles.costItemText}>
              <Typography 
                variant="body"
                weight="regular"
                color="black"
                className={styles.costItemName}
              >
                {session.name} 
              </Typography>
              <Typography 
                variant="body"
                weight="semibold"
                color="black"
                className={styles.price}
              >
                {session.price}
                <span>c</span>
              </Typography>
            </li>
          ) )}
        </ul>
      </div>
    </AnimationBox>
  );
};