import { Typography } from "@Typography";
import Button from "@Ui/Buttons/Button";
import styles from "./StartBlock.module.scss";
// import Banner from "@Assets/Images/startBlock.webp";
import Banner2 from "../../../../Assets/Images/StartBlockFoto.webp";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useSocialStore } from "@Modules/HomePageModule/store/useSocialStore";
import alternatePhoto from "@Assets/Images/alternativePhoto.jpg";


export const StartBlock = () => {
  const { t } = useTranslation( "homepage" );
  const { data, getData } = useSocialStore();

  const whatsappData = data.find( ( item ) => item.urls.includes( "wa.me" ) );

  const handleImageError = ( event ) => {
    event.target.src = alternatePhoto;
  };

  useEffect( () => {
    getData();
  }, [ getData ] );

  return (
    <div className="Container">
      <div className={styles.start_block}>
        <div className={styles.start_block_content}>
          <div className={styles.text}>
            <Typography variant="h1" color="DarkBlue" weight="medium">
              {t( "start_block.title" )}
            </Typography>
            <Typography variant="h6">
              {t( "start_block.description" )}
            </Typography>
            <Button href={ whatsappData && whatsappData.urls}
              target={"_blank"}>
              {t( "start_block.button_text" )}
            </Button>
          </div>
          <div className={styles.photo}>
            <img
              src={Banner2 || alternatePhoto} 
              alt="Баннер"
              onError={handleImageError}
            />
          </div>
        </div>
      </div>
    </div>
  );
};



