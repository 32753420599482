import { useEffect } from "react";
import { storeOurBlog } from "../../store/storeOurBlogPage";
import { Typography } from "@Ui/Typography/Typography";
import { OurBlogCard } from "../OurBlogCard/OurBlogCard";
import { useTranslation } from "react-i18next";
import Pagination from "@Components/Pagination/Pagination";
import styles from "./OurBlogPage.module.scss";
import Loading from "@Ui/Loading/Loading";

export const OurBlogPage = () => {

  const { t } = useTranslation( "ourBlog" );

  const {
    blogData,
    fetchBlog,
    currentPage,
    nextPageURL,
    previousPageURL,
    loading
  } = storeOurBlog();

  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [ currentPage ] );

  useEffect( () => {
    fetchBlog( currentPage );
  }, [ fetchBlog, currentPage ] );

  if ( loading ) (
    <Loading />
  );

  return (
    <section className="Container">
      <div className={styles.titile}>
        <Typography variant="h1" color="DarkBlue" weight="medium">
          {t( "title" )}
        </Typography>
      </div>
      <div className={styles.content}>
        {blogData.results && blogData.results.map( ( blog ) => (
          <OurBlogCard key={blog.id} blog={blog} loading={loading} />
        ) )}
        {( nextPageURL || previousPageURL ) &&
          <Pagination
            count={blogData}
            fetchData={fetchBlog}
            currentPage={currentPage}
            itemCount={7}
          />}
      </div>
    </section>
  );
};
