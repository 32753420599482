import "./SocialIcons.scss";
import { useSocialStore } from "@Modules/HomePageModule/store/useSocialStore";
import { useEffect } from "react";
import classNames from "classnames";
import alternatePhoto from "@Assets/Images/alternativePhoto.jpg";

export const SocialIcons = ( { position = "fixed", flexDirection = "column", top = "50%", className, zIndex = "10" } ) => {
  const { data, getData } = useSocialStore();

  useEffect( () => {
    getData();
  }, [ getData ] );

  const handleImageError = ( event ) => {
    event.target.src = alternatePhoto;
  };


  return (
    <div
      className={classNames(
        "social_icons",
        {
          "social_icons--fixed": position === "fixed",
          "social_icons--relative": position === "relative",
        },
        className
      )}
      style={{ top, flexDirection, zIndex }}
    >
      {data &&
        data.map( ( item, index, ) => (
          <a
            key={index}
            href={item.urls}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="social_icons__items">
              <img 
                src={item.icon || alternatePhoto} 
                alt="social icon"
                aria-label="social icon"
                onError={handleImageError}
              />
            </div>
          </a>
        ) )}
    </div>
  );
};

