import vector from "@Assets/Icons/socials/Vector.svg";
import styles from "./FooterDownBlock.module.scss";
import { Typography } from "@Ui/Typography/Typography";
import { COPYRIGHT } from "@Utils/Constans/constants";


export const FooterDownBlock = () => (
  <section className={styles.footerDownBlock} >
    <div className="Container">
      <div className={styles.footerDownBlockContent}>
        <a href={COPYRIGHT} target="_blank">
          <Typography
            variant="h6"
            color="black"
            weight="medium"
          >
              MADE BY GEEKS PRO
          </Typography>
          <div className={styles.footerIcon}>
            <img src={vector} alt="vector" />
          </div>
        </a>
      </div>
    </div>
  </section>
);
