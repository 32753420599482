import styles from "./OurBlog.module.scss";
import { BlogCard }from "@Ui/BlogCard/BlogCard"; 
import { useOurBlogStore }from "../store/useOurBlogStore";
import Button from "@Ui/Buttons/Button";
import { Typography } from "@Typography";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { paths } from "@Utils/Constans/constants";
import { useMouseScroll } from "@Utils/hooks/useMouseScroll";
import Loading from "@Ui/Loading/Loading";
import { useTranslation } from "react-i18next";


export const OurBlog = () => {
  const scrollRef = useMouseScroll();
  const { data, loading, getData } = useOurBlogStore();
  const { t } = useTranslation( "homepage" );
  
  useEffect( () => {
    getData();
  }, [ getData ] );
  
  if ( loading ) return <Loading/>;
  return (
    <div>
      <div className="Container">
        <div className={styles.content}>
          <Typography
            variant="h1"
            weight="medium" 
            color="DarkBlue"
            className={styles.title}
          >
            {t( "ourBlog.title" )}
          </Typography>
          <div className={styles.scroll}
            ref={scrollRef}
          >
            {data && data.map( ( item, index ) => <BlogCard key={index} {...item} /> )}
          </div>
          <Button size={"detail"}>
            <Link to={paths.ourblog}>
              {t( "ourBlog.btn" )}
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
};
