import { useTranslation } from "react-i18next";
import { Typography } from "@Typography";
import styles from "./ReviwsSlider.module.scss";
import AltPhoto from "@Assets/Images/alternativePhoto.jpg";

const ReviwsSlider = ( { reviws } ) => {

  const { t } = useTranslation( "homepage" );

  const replaceImage = ( error ) => {
    error.target.src = AltPhoto;
  };
  
  return (
    <div className={styles.card}>
      <div className={styles.client}>
        <div className={styles.photo}>
          <img src={reviws ? reviws.photo : AltPhoto} alt={reviws.title} onError={replaceImage}/>
        </div>
        <div className={styles.client_name}>
          <Typography variant="h4" color="DarkBlue" weight="medium">
            {`${reviws.title}, ${reviws.year}`}
          </Typography>
        </div>
      </div>
      <div className={styles.review}>
        <div className={styles.problem}>
          <Typography variant="h5" color="blue" weight="medium">{t( "reviews.problems" )}:</Typography>
          <Typography variant="bodySmall" className={styles.text}>
            { reviws.problems }
          </Typography>
        </div>
        <div className={styles.response}>
          <Typography variant="h5" color="blue" weight="medium">{t( "reviews.solutions" )}:</Typography>
          <Typography variant="bodySmall" className={styles.text}>
            { reviws.decision }
          </Typography>
        </div>
      </div>
    </div>
  ); };

export default ReviwsSlider;