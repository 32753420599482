import { OurBlogPage } from "@Modules/OurBlogModule";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
export const OurBlog = () => {
  const { t } = useTranslation( "navigation" );
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t( "our_blog" )}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <OurBlogPage/>
    </>
  );
};