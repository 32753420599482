import cn from "classnames";
import styles from "./Button.module.scss";
import { Typography } from "@Typography";

const Button = ( { children, color, size, onClick, href, target, className } ) => (
  href ? (
    <button className={cn( styles["btn"], styles[color], styles[size], className )} onClick={onClick}>
      <a href={href} target={target} className={styles.href}>
        <Typography variant="h5" weight={"medium"}>
          {children}
        </Typography>
      </a>
    </button>
  ) : (
    <button className={cn( styles["btn"], styles[color], styles[size], className )} onClick={onClick}>
      <Typography variant="h5" weight={"medium"}>
        {children}
      </Typography>
    </button>
  )
);

export default Button;