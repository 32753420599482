import { AnimationBox } from "@Ui/AnimationBox/AnimationBox";
import styles from "./Center.module.scss";
import CENTER from "@Assets/Images/center.webp";
import alternatePhoto from "@Assets/Images/alternativePhoto.jpg";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { Typography } from "@Ui/Typography/Typography";


export const Center = () =>  {
  const { t } = useTranslation( "homepage" );

  const handleImageError = ( event ) => {
    event.target.src = alternatePhoto;
  };


  return  (
    <div className="Container">
      <div className={styles.centerContainer}>
        <div className={styles.imgCenter}>
          <img 
            src={CENTER || alternatePhoto}
            alt="Center"
            onError={handleImageError}
          />
        </div>
        <AnimationBox animationType="fadeIn" delay={0.5} className={styles.infoCenter}>
          <h2 className={styles.heading}>
            {parse( t( "center.title" )  )}
          </h2>
          <Typography variant="bodySmall" weight="regular" className={styles.description}>
            {t( "center.description" )}
          </Typography>
        </AnimationBox>
      </div>
    </div>
  );
  
};